:global body {
	--success-color: #1ab394;
	--err-color: #ed5565;
	--warn-color: #f8ac59;

	--regular-font: SegoeWPRegular, Arial, sans-serif;
	--light-font: SegoeWPLight, Arial, sans-serif;
	--bold-font: SegoeWPBold, Arial, sans-serif;
}

:global body {
	margin: 0;
	padding: 0;
	font-family: var(--regular-font)
}

:global #modal-root {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

:global .blur {
	filter: blur(5px);
	height: 100vh;
	overflow: hidden;
}

:global input::-ms-clear {
	display: none;
}

:global input::-ms-clear {
	display: none;
}
