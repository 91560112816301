:local .TableScroll {
    position: relative;
    display: flex;
    max-width: 960px;
    width: 960px;
    margin: auto;
    overflow: hidden;
    border: 1px solid #000;
}

:local .TableWrap {
    width: 100%;
    overflow: auto;
}

:local .TableScroll table {
    width: 100%;
    /*margin:auto;*/
    border-collapse: separate;
    border-spacing: 0;
}

:local .TableScroll th, :local .TableScroll td {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    white-space: nowrap;
    vertical-align: top;
}

:local .TableScroll thead, :local .TableScroll tfoot {
    background: #f9f9f9;
}

:local .InfoRow {
    height: 90px;
    overflow: hidden;
    display: flex;
    max-width: 960px;
    width: 960px;
    margin: auto;
}

:local .InfoRowData {
}

:local .InfoRowSpace {
    height: 90px;
}

:local .StickyHeader {
    position: fixed;
    top: 0;
    display: flex;
    width: 960px;
    overflow: hidden;
}
