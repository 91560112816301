.container {
	border-collapse: separate;
	border-spacing: 0;
	display: grid;
	grid-template-columns: auto 270px;
	grid-template-areas: "table controls";
	font-size: 11px;
	color: #c0c0c0;
	overflow: hidden;
	height: 498px;
}

.container * {
	box-sizing: border-box;
}

.container :global .sticky-table-cell {
	border-bottom: 1px solid #dadada;
	border-right: 1px solid #f4f4f4;
	/*min-height: 50px;*/
}

.container :global .sticky-table .x-scrollbar {
	width: calc(100% - 18px);
}

.scroll {
	overflow-x: auto;
	border-top: 2px solid #d9d9d9;
	grid-area: table;
	height: 500px;
}

.cell {
	height: 100%;
	padding: 8px;
	overflow-wrap: break-spaces;
	white-space: normal;
	word-break: break-word;
	text-transform: none;
	font-family: var(--regular-font);
	color: #000;
	cursor: pointer;
}

.right {
	text-align: end;
}

.headerCell {
	composes: cell;
	border-right: 1px solid #e6e6e6 !important;
	vertical-align: middle;
}

.binded {
	background-color: #ecffe8;
}

.selected {
	background-color: #e8f7ff;
}

.header {
	text-align: center;
	/*height: 100%;*/
	min-width: 120px;
	overflow-wrap: break-spaces;
	white-space: normal;
	padding: 5px 5px;
	/*font-weight: bold;*/
	text-transform: uppercase;
	color: #000;
	font-family: var(--bold-font);
	word-break: normal;
	word-wrap: normal;
}

@supports (word-break: break-word) {
	.header {
		word-break: break-word;
	}
}

.headerNotLinked {
	composes: header;
	color: #e6e6e6;
}

.subHeader {
	border: none !important;
	cursor: pointer;
}

.subHeaderContent {
	background-color: #DBDBDB;
	font-weight: normal;
	font-family: var(--bold-font);
	/*display: flex;*/
	text-align: center;
	/*justify-content: center;*/
	/*flex-direction: column;*/
	max-height: 33px;
	font-size: 11px;
	text-transform: uppercase;
	height: 33px;
	border-bottom: 8px solid transparent;
	border-top: 8px solid transparent;
	padding: 2px 0 2px 0;
	position: relative;
	color: #000;
}

.subHeaderContent span {
	border-left: 1px solid #e6e6e6;
	border-right: 1px solid #e6e6e6;
	display: block;
}

.subHeaderSelected {
	font-weight: bold;
	/*display: block !important;*/
	/*height: 20px;*/
}

.subHeaderSelected .subHeaderContent {
	/*background-color: #DBDBDB;*/
	/*border-bottom: 8px solid transparent ;*/
	/*border-top: 8px solid transparent;*/

}

.subHeaderSelected .subHeaderContent::before {
	top: -9px;
	left: 50%;
	border: solid 5px transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	border-top-color: #e5f4ff;
	margin-left: -5px;
}

.subHeaderSelected .subHeaderContent::after {
	bottom: -9px;
	left: 50%;
	border: solid 5px transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	border-bottom-color: #e5f4ff;
	margin-left: -5px;
}

.leftColumn {
	height: 100%;
	/*width: 100%;*/
	padding: 8px;
	/*display: flex;*/
	/*align-items: center;*/
	color: #000;
	font-family: var(--bold-font);
}

:global .sticky-table-corner :local .left {
	border: none !important;
}

:global .sticky-table-corner .sticky-table-row:first-child .sticky-table-cell {
	border-right: 1px solid #e6e6e6 !important;
}

:global .sticky-table-row :local .cell:last-child div {
	margin-right: 10px;
}

.leftColumn div {
	float: right;
}

.deletedRow {
	background-color: #ebebeb;
	opacity: .5;
	color: #aeaeae;
}

.left {
	background-color: #DBDBDB;
	border-bottom: 1px solid #f4f4f4 !important;
	opacity: 1;
}

.removeButton {
	background-color: transparent;
	width: 22px;
	/* height: 30px; */
	float: left;
	border: none;
	margin: 0;
	margin-top: -2px;
	padding: 0;
	font-size: 16px;
	cursor: pointer;
	color: #c0c0c0;
	outline: none;
}

.removeButton:hover {
	color: #000;
}

.deletedRow .removeButton {
	color: var(--err-color);
}

.deletedRow .leftColumn {
	color: #aeaeae;
}

.controls {
	display: flex;
	grid-area: controls;
	flex-direction: column;
	padding: 15px 20px 0;
	margin-left: 20px;
	height: 500px;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #e6e6e6;
	color: #000;
}

.controls label::after {
	content: none;
}

.controls input {
	margin: 0 8px 0 0;
}

.controls h2 {
	font-size: 11px;
	font-weight: bold;
	margin: 0 0 10px;
	padding: 0;
	text-transform: uppercase;
}

.controls :global .items {
	padding-bottom: 15px;
}

.label {

}

.filter {
	display: flex;
	align-items: start;
	margin-bottom: 5px;
}

.filter label {
	font-family: var(--regular-font);
	color: #000;
	text-transform: none;
}

.controls div:first-child .filter:first-child {
	margin-bottom: 15px;
}

label.disabled {
	color: #ccc;
}

.errors {
	display: none;
	width: calc(100% - 20px);
	padding: 20px;
	border: 1px solid var(--err-color);
	margin: 0 20px 15px;
}

.errors:global.show {
	display: flex;
}

.errors i {
	color: var(--warn-color);
	margin-right: 10px;
}

.deletedRow:global.sticky-table-cell {
	background-color: #f0f0f0;
	border-bottom: 1px solid #cecece;
	border-right: 1px solid #e1e1e1;
}
.label :global span.required {
	color: #3dcc4a;
}

.wizard-controls {
	display: flex;
	justify-content: space-between;
	margin: 30px 0;
}

/*Firefox double bottom scrollbar workaround*/
@supports (scrollbar-width: none) {
	.container :global .sticky-table .sticky-table-x-wrapper {
		scrollbar-width: none;
	}
}