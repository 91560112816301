:local .TokenInput {
	display: flex;
	flex-direction: column;
	min-width: 310px;
	min-height: 33px;
	background: white;
	text-decoration: none;
	color: #555;
	border: 1px solid #d1d1d1;
	border-radius: 2px;
	font-size: 14px;
	font-weight: 600;
	transition: border .125s ease, background .125s ease;
}

:local .List {
	list-style: none;
	margin: 0;
	padding: 0;
}

:local .ListItem {
	cursor: pointer;
	user-select: none;
	min-height: 20px;
	padding: 5px;
}

:local .ListItem:hover {
	background-color: #e5e6e7;
	color: #000;
}

:local .Grid {
	margin: 4px 6px;
}

:local .Dropdown {
	position: absolute;
	border: 1px solid #e5e6e7;
	background-color: #FFF;
	overflow-y: scroll;
	max-height: 300px;
	z-index: 9999;
}

:local .Text {
	height: 21px;
	width: 25px;
	box-sizing: content-box;
	line-height: 18px;
	margin: 1px 1px 1px 5px;
	outline: none;
	border: none;
}

:local .ListItemAccount {
	display: flex;
}

:local .ListItemAccount:nth-child(2n+2) {
	background-color: #cccccc36;
}

:local .ListItemAccount > div {
	width: 350px;
	max-width: 350px;
	min-width: 350px;
}

:local .Pseudonym {
	font-weight: lighter;
	font-style: italic;
}

:local .Scope {
	font-size: 11px;
	font-weight: lighter;
}

:local .Alias {
	font-weight: lighter;
	white-space: normal;
}

:local .Highlighted {
	background-color: #c3c3c3;
}
