:local .search-result-item-title {

}

:local .search-result-item-subtitle {
    color: #959595;
}



