body {
	--success-color: #1ab394;
	--err-color: #ed5565;
	--warn-color: #f8ac59;

	--regular-font: SegoeWPRegular, Arial, sans-serif;
	--light-font: SegoeWPLight, Arial, sans-serif;
	--bold-font: SegoeWPBold, Arial, sans-serif;
}

body {
	margin: 0;
	padding: 0;
	font-family: var(--regular-font)
}

#modal-root {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.blur {
	-webkit-filter: blur(5px);
	        filter: blur(5px);
	height: 100vh;
	overflow: hidden;
}

input::-ms-clear {
	display: none;
}

input::-ms-clear {
	display: none;
}

._22AW_J_i1L0NXAuax32rWw {
    width: 88%;
    height: 34px;
    font-size: 16px;
    border: none;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
}

._1AedkSRLJ9Tr2_3oxsA_SJ {
    display: flex;
    border: none;
}

._1AedkSRLJ9Tr2_3oxsA_SJ i {
    color: #d1d1d1;
    font-size: 24px;
    padding: 6px 8px;
    /*width: 24px;*/
    /*height: 24px;*/
    text-align: center;
}

._17d6vL8qsTct3BLidEs0oh {
    cursor: pointer;
}

._17d6vL8qsTct3BLidEs0oh:hover {
    color: #000
}

._1AedkSRLJ9Tr2_3oxsA_SJ button {
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
}

._20ccN3BAadkw7D4rmurmH6 {
    min-height: 0;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid #d9d9d9;
}

._20ccN3BAadkw7D4rmurmH6 ._active {
    background-color: #cdeaff;
}

._20ccN3BAadkw7D4rmurmH6 ._selectable {
    cursor: pointer;
}

._3jTsqSoUdWKB8GMlpvumpr {

}

._3mYT2HqbJyiJgk-6E_Ympd {
    color: #959595;
}




._1IercASXl7daAIxl8yyZE1 {
    font-size: 11px;
    text-transform: uppercase;
    /*border: 1px solid #d9d9d9;*/
    /*border-top: none;*/

    display: flex;
    flex-direction: row;
}

._19XvPFniY6bbsjsJpSxz32 {
    width: 100%;
}

._19XvPFniY6bbsjsJpSxz32 ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

._19XvPFniY6bbsjsJpSxz32 li {
    padding: 6px 9px;
    cursor: pointer;
}

._2Al3muxahsgJ9wctX6Y5Ab {
    /*width: 15%;*/
    /*width: 162px;*/
    padding: 6px 9px;
    text-align: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #f5f5f5;
    /*border-right: 1px solid #d9d9d9;*/
}

/*:local  .search-result-group-items li:hover {*/
/*background-color: #cdeaff;*/
/*}*/

._19XvPFniY6bbsjsJpSxz32 a {
    text-decoration: none;
    color: inherit;
}

.wFDWj0UqIe_Hy9XIvfNgh {
    color: #006fe3;
}

.wFDWj0UqIe_Hy9XIvfNgh a {
    width: 100%;
    height: 100%;
    display: block;
}

._3iJSlirKMbm_AvUNNO719C {
    background-color: #c1c1c1;
    border-radius: 10px;
    color: white;
    padding: 3px 6px;
    min-width: 20px;
    display: inline-block;
    text-align: center;
}

._3lUQYapASUCeoCEiWfG0zy {
    cursor: pointer;
    margin-right: 5px;
    font-size: 13px;
    text-transform: capitalize;
}

._1-Qi8xeCJ_85IkwXwMUjuQ {
    width: 590px;
    display: block;
    position: relative;
    margin: 30px auto;
    box-shadow: 0 0 20px 0 rgba(00, 00, 00, 0.2);
    background-color: #FFF;
    border-radius: 1px;
}

._3PVtDkIcT1gnlDUxkZU75F {
    /*border: 1px solid #d9d9d9;*/
}

.GlobalSearchForm-appear {
    max-height: 0;
    overflow: hidden;

}

.GlobalSearchForm-appear.GlobalSearchForm-appear-active {
    max-height: 40px;
    -webkit-transition: max-height .5s;
    transition: max-height .5s;
    overflow: hidden;
}

.h_awChHZAmXHyodKbMvS7 {
    /*display: none;*/
}

/* todo нужно привести стили впорядок*/

/* Claimer quantity graph */

.claimer-licensors input[type=checkbox],
.claimer-licensors input[type=radio] {
    margin: 0;
}

.claimer-licensors a {
    cursor: pointer;
}

.claimer-licensors {
    font-family: SegoeWPSemibold, Arial, sans-serif;
    font-size: 10px;
}

.claimer-licensors__title {
    text-transform: uppercase;
    fill: #4d4d4d;
}

.claimer-licensors__bar {
    /**/
}

.claimer-licensors__bar__count {
    fill: #dae1e6;
}

.claimer-licensors__bar__unconfirmed {
    fill: #9b99ff;
}

.claimer-licensors__bar__ignored {
    fill: #aec7e8;
}

.claimer-licensors__bar__identified {
    fill: #9b99ff;
}

.claimer-licensors__bar__rightless {
    fill: #b3b3b3;
}

.claimer-licensors__bar__disputed {
    fill: #ff4a4a;
}

.claimer-licensors__bar__disputed_copublishing {
    fill: #ff2e46;
}

.claimer-licensors__bar__disputed_contribution {
    fill: #ff8c39;
}

.claimer-licensors__bar__claimable {
    fill: #6ce270;
}

.claimer-licensors__bar__claimed {
    fill: #ffd93e;
}

.claimer-licensors__bar__accounted {
    fill: #7fd2ff;
}

.claimer-licensors__bar__unaccounted {
    fill: #d3eaff;
}

.claimer-licensors__bar__withheld {
    fill: #fa5bff;
}

.claimer-licensors g.tick text {
    font-size: 11px;
    fill: #999;
}

.claimer-licensors g.tick line {
    stroke: #f5f5f5;
}

.claimer-licensors__controls,
.claimer-licensors__filters,
.claimer-licensors__legend__text {
    text-transform: uppercase;
}

.claimer-licensors__controls__header,
.claimer-licensors__filters__header {
    margin-bottom: 6px;
    margin-left: 1px;
}

.claimer-licensors__title {
    text-transform: uppercase;
    fill: #4d4d4d;
}

.claimer-licensors__controls-field {
    margin: 3px 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.claimer-licensors__controls > div,
.claimer-licensors__filters > div {
    display: flex;
    flex-direction: column;
}

.claimer-licensors__controls input[type=radio], .claimer-licensors__filters input[type=checkbox] {
    margin: 0 6px 0 0;
    cursor: pointer;
}

.claimer-licensors input[type=checkbox] {
    cursor: pointer;
}

.claimer-licensors rect,
.claimer-licensors line {
    shape-rendering: crispedges;
}

.claimer-licensors rect {
    cursor: pointer;
}

._17Ingd2HG_d1f7vduYwDjW {
    /*display: none;*/
}

.claimer-diagram__container {
    font-family: SegoeWPSemibold, Arial, sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    fill: #4d4d4d;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.claimer-diagram__container rect,
.claimer-diagram__container line {
    shape-rendering: crispedges;
}

.claimer-diagram__container_diagram .period-line {
    /**/
}

.claimer-diagram__container_diagram .count {
    stroke: #dae1e6;
}

.claimer-diagram__container_diagram .unconfirmed {
    stroke: #9b99ff;
}

.claimer-diagram__container_diagram .ignored {
    stroke: #aec7e8;
}

.claimer-diagram__container_diagram .identified {
    stroke: #9b99ff;
}

.claimer-diagram__container_diagram .rightless {
    stroke: #b3b3b3;
}

.claimer-diagram__container_diagram .disputed {
    stroke: #ff4a4a;
}

.claimer-diagram__container_diagram .disputed_copublishing {
    stroke: #ff2e46;
}

.claimer-diagram__container_diagram .disputed_contribution {
    stroke: #ff8c39;
}

.claimer-diagram__container_diagram .claimable {
    stroke: #6ce270;
}

.claimer-diagram__container_diagram .claimed {
    stroke: #ffd93e;
}

.claimer-diagram__container_diagram .accounted {
    stroke: #7fd2ff;
}

.claimer-diagram__container_diagram .unaccounted {
    stroke: #d3eaff;
}

.claimer-diagram__container_diagram .withheld {
    stroke: #fa5bff;
}

.claimer-diagram__container_diagram circle.count {
    fill: #dae1e6;
}

.claimer-diagram__container_diagram circle.unconfirmed {
    fill: #9b99ff;
}

.claimer-diagram__container_diagram circle.ignored {
    fill: #aec7e8;
}

.claimer-diagram__container_diagram circle.identified {
    fill: #9b99ff;
}

.claimer-diagram__container_diagram circle.rightless {
    fill: #b3b3b3;
}

.claimer-diagram__container_diagram circle.disputed {
    fill: #ff4a4a;
}

.claimer-diagram__container_diagram circle.disputed_copublishing {
    fill: #ff2e46;
}

.claimer-diagram__container_diagram circle.disputed_contribution {
    fill: #ff8c39;
}

.claimer-diagram__container_diagram circle.claimable {
    fill: #6ce270;
}

.claimer-diagram__container_diagram circle.claimed {
    fill: #ffd93e;
}

.claimer-diagram__container_diagram circle.accounted {
    fill: #7fd2ff;
}

.claimer-diagram__container_diagram circle.unaccounted {
    fill: #d3eaff;
}

.claimer-diagram__container_diagram circle.withheld {
    fill: #fa5bff;
}

.claimer-diagram__container input[type=checkbox],
.claimer-diagram__container input[type=radio] {
    margin: 0;
}

.claimer-diagram__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.claimer-diagram__container_diagram {
    width: 580px;
    height: 500px;
    max-width: 580px;
    max-height: 500px;
}

.claimer-diagram__container_controls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    width: 180px;
    max-width: 180px;
}

.claimer-diagram__container_controls input[type=radio],
.claimer-diagram__container_controls input[type=checkbox] {
    margin: 0 6px 0 0;
    cursor: pointer;
}

.claimer-diagram__container_controls-group {

    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

}

.claimer-diagram__container_diagram g.tick text {
    font-size: 11px;
    fill: #999;
}

#claimer_quantity_diagram g.quarters.axis > g:nth-child(2) > text {
    opacity: 0;
}

.claimer-diagram__container_diagram g.tick line {
    stroke: #f5f5f5;
}

.claimer-diagram__container_diagram .quarters g.tick line {
    stroke: #dadada;
    stroke-width: 1px;
}

.claimer-diagram__container_diagram .months g.tick line {
    stroke: #f6f6f6;
    stroke-width: 1px;
}

.claimer-diagram__container_diagram .years g.tick line,
.claimer-diagram__container_diagram .quarters line,
.claimer-diagram__container_diagram .gray-line {
    stroke: #dadada;
    stroke-width: 2px;
}

.claimer-diagram__container_diagram .years .domain {
    stroke: #dadada;
    stroke-width: 2px;
}

.claimer-diagram__container_diagram .years .domain {
    stroke: #dadada;
    stroke-width: 2px;
}

.claimer-diagram__container_diagram circle {
    stroke: transparent;
    cursor: pointer;
}

._2TDJNWtRdG_jfmK7Edxsr3 {
    /*display: none;*/
}

.claimer-diagram__container {
    font-family: SegoeWPSemibold, Arial, sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    fill: #4d4d4d;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.claimer-diagram__container rect,
.claimer-diagram__container line {
    shape-rendering: crispedges;
}

.claimer-diagram__container_diagram .period-line {
    /**/
}

.claimer-diagram__container_diagram .count {
    stroke: #dae1e6;
}

.claimer-diagram__container_diagram .unconfirmed {
    stroke: #9b99ff;
}

.claimer-diagram__container_diagram .ignored {
    stroke: #aec7e8;
}

.claimer-diagram__container_diagram .identified {
    stroke: #9b99ff;
}

.claimer-diagram__container_diagram .rightless {
    stroke: #b3b3b3;
}

.claimer-diagram__container_diagram .disputed {
    stroke: #ff4a4a;
}

.claimer-diagram__container_diagram .disputed_copublishing {
    stroke: #ff2e46;
}

.claimer-diagram__container_diagram .disputed_contribution {
    stroke: #ff8c39;
}

.claimer-diagram__container_diagram .claimable {
    stroke: #6ce270;
}

.claimer-diagram__container_diagram .claimed {
    stroke: #ffd93e;
}

.claimer-diagram__container_diagram .accounted {
    stroke: #7fd2ff;
}

.claimer-diagram__container_diagram .unaccounted {
    stroke: #d3eaff;
}

.claimer-diagram__container_diagram .withheld {
    stroke: #fa5bff;
}

.claimer-diagram__container_diagram circle.count {
    fill: #dae1e6;
}

.claimer-diagram__container_diagram circle.unconfirmed {
    fill: #9b99ff;
}

.claimer-diagram__container_diagram circle.ignored {
    fill: #aec7e8;
}

.claimer-diagram__container_diagram circle.identified {
    fill: #9b99ff;
}

.claimer-diagram__container_diagram circle.rightless {
    fill: #b3b3b3;
}

.claimer-diagram__container_diagram circle.disputed {
    fill: #ff4a4a;
}

.claimer-diagram__container_diagram circle.disputed_copublishing {
    fill: #ff2e46;
}

.claimer-diagram__container_diagram circle.disputed_contribution {
    fill: #ff8c39;
}

.claimer-diagram__container_diagram circle.claimable {
    fill: #6ce270;
}

.claimer-diagram__container_diagram circle.claimed {
    fill: #ffd93e;
}

.claimer-diagram__container_diagram circle.accounted {
    fill: #7fd2ff;
}

.claimer-diagram__container_diagram circle.unaccounted {
    fill: #d3eaff;
}

.claimer-diagram__container_diagram circle.withheld {
    fill: #fa5bff;
}

.claimer-diagram__container input[type=checkbox],
.claimer-diagram__container input[type=radio] {
    margin: 0;
}

.claimer-diagram__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.claimer-diagram__container_diagram {
    width: 580px;
    height: 500px;
    max-width: 580px;
    max-height: 500px;
}

.claimer-diagram__container_controls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    width: 180px;
    max-width: 180px;
}

.claimer-diagram__container_controls input[type=radio],
.claimer-diagram__container_controls input[type=checkbox] {
    margin: 0 6px 0 0;
    cursor: pointer;
}

.claimer-diagram__container_controls-group {

    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

}

.claimer-diagram__container_diagram g.tick text {
    font-size: 11px;
    fill: #999;
}

#claimer_quantity_diagram g.quarters.axis > g:nth-child(2) > text {
    opacity: 0;
}

.claimer-diagram__container_diagram g.tick line {
    stroke: #f5f5f5;
}

.claimer-diagram__container_diagram .quarters g.tick line {
    stroke: #dadada;
    stroke-width: 1px;
}

.claimer-diagram__container_diagram .months g.tick line {
    stroke: #f6f6f6;
    stroke-width: 1px;
}

.claimer-diagram__container_diagram .years g.tick line,
.claimer-diagram__container_diagram .quarters line,
.claimer-diagram__container_diagram .gray-line {
    stroke: #dadada;
    stroke-width: 2px;
}

.claimer-diagram__container_diagram .years .domain {
    stroke: #dadada;
    stroke-width: 2px;
}

.claimer-diagram__container_diagram .years .domain {
    stroke: #dadada;
    stroke-width: 2px;
}

.claimer-diagram__container_diagram circle {
    stroke: transparent;
    cursor: pointer;
}

/*!
* Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
*/
.Flag_Flag__31WIK {
	display: inline-block;
	width: 16px;
	height: 11px;
	background: url(/static/media/flags.f8428f83.png) no-repeat;
}

.Flag_Flag-ad__yayF6 {
	background-position: -16px 0;
}

.Flag_Flag-ae__1xLPu {
	background-position: -32px 0;
}

.Flag_Flag-af__1LDbD {
	background-position: -48px 0;
}

.Flag_Flag-ag__32bCy {
	background-position: -64px 0;
}

.Flag_Flag-ai__1TvpR {
	background-position: -80px 0;
}

.Flag_Flag-al__3T6Cg {
	background-position: -96px 0;
}

.Flag_Flag-am__WY8HL {
	background-position: -112px 0;
}

.Flag_Flag-an__2xNga {
	background-position: -128px 0;
}

.Flag_Flag-ao__1PQmS {
	background-position: -144px 0;
}

.Flag_Flag-ar__2niBl {
	background-position: -160px 0;
}

.Flag_Flag-as__3kUQr {
	background-position: -176px 0;
}

.Flag_Flag-at__2ObNk {
	background-position: -192px 0;
}

.Flag_Flag-au__2Jyqv {
	background-position: -208px 0;
}

.Flag_Flag-aw__3k5hc {
	background-position: -224px 0;
}

.Flag_Flag-ax__3rYhr {
	background-position: -240px 0;
}

.Flag_Flag-az__33qMq {
	background-position: 0 -11px;
}

.Flag_Flag-ba__3FKbV {
	background-position: -16px -11px;
}

.Flag_Flag-bb__12ZKp {
	background-position: -32px -11px;
}

.Flag_Flag-bd__20OhN {
	background-position: -48px -11px;
}

.Flag_Flag-be__3ZV4k {
	background-position: -64px -11px;
}

.Flag_Flag-bf__2Y_Xr {
	background-position: -80px -11px;
}

.Flag_Flag-bg__3noQv {
	background-position: -96px -11px;
}

.Flag_Flag-bh__2KZ-f {
	background-position: -112px -11px;
}

.Flag_Flag-bi__1Fk7- {
	background-position: -128px -11px;
}

.Flag_Flag-bj__3X-oA {
	background-position: -144px -11px;
}

.Flag_Flag-bm__3myEz {
	background-position: -160px -11px;
}

.Flag_Flag-bn__1m4dC {
	background-position: -176px -11px;
}

.Flag_Flag-bo__1QL46 {
	background-position: -192px -11px;
}

.Flag_Flag-br__24Kts {
	background-position: -208px -11px;
}

.Flag_Flag-bs__dPbc0 {
	background-position: -224px -11px;
}

.Flag_Flag-bt__2-QDb {
	background-position: -240px -11px;
}

.Flag_Flag-bv__3F6qc {
	background-position: 0 -22px;
}

.Flag_Flag-bw__uyzL5 {
	background-position: -16px -22px;
}

.Flag_Flag-by__3MRbb {
	background-position: -32px -22px;
}

.Flag_Flag-bz__A9vQI {
	background-position: -48px -22px;
}

.Flag_Flag-ca__3RHE4 {
	background-position: -64px -22px;
}

.Flag_Flag-catalonia__2FK4b {
	background-position: -80px -22px;
}

.Flag_Flag-cd__3uxkF {
	background-position: -96px -22px;
}

.Flag_Flag-cf__sRaIg {
	background-position: -112px -22px;
}

.Flag_Flag-cg__13Zdb {
	background-position: -128px -22px;
}

.Flag_Flag-ch__3Y-p9 {
	background-position: -144px -22px;
}

.Flag_Flag-ci__22VJM {
	background-position: -160px -22px;
}

.Flag_Flag-ck__ZdsXQ {
	background-position: -176px -22px;
}

.Flag_Flag-cl__1j8d- {
	background-position: -192px -22px;
}

.Flag_Flag-cm__2S0X_ {
	background-position: -208px -22px;
}

.Flag_Flag-cn__UAIVv {
	background-position: -224px -22px;
}

.Flag_Flag-co__2b7u9 {
	background-position: -240px -22px;
}

.Flag_Flag-cr__ySBqv {
	background-position: 0 -33px;
}

.Flag_Flag-cu__1hZLa {
	background-position: -16px -33px;
}

.Flag_Flag-cv__1eqMr {
	background-position: -32px -33px;
}

.Flag_Flag-cw__3JjS2 {
	background-position: -48px -33px;
}

.Flag_Flag-cy__3tC1z {
	background-position: -64px -33px;
}

.Flag_Flag-cz__1yFQ7 {
	background-position: -80px -33px;
}

.Flag_Flag-de__3R-IQ {
	background-position: -96px -33px;
}

.Flag_Flag-dj__1A-7c {
	background-position: -112px -33px;
}

.Flag_Flag-dk__1QBCg {
	background-position: -128px -33px;
}

.Flag_Flag-dm__1DcEe {
	background-position: -144px -33px;
}

.Flag_Flag-do__1-EYg {
	background-position: -160px -33px;
}

.Flag_Flag-dz__o7q95 {
	background-position: -176px -33px;
}

.Flag_Flag-ec__yXlWe {
	background-position: -192px -33px;
}

.Flag_Flag-ee__2D6y7 {
	background-position: -208px -33px;
}

.Flag_Flag-eg__2uilQ {
	background-position: -224px -33px;
}

.Flag_Flag-eh__sy1vH {
	background-position: -240px -33px;
}

.Flag_Flag-england__FMGee {
	background-position: 0 -44px;
}

.Flag_Flag-er__3HV7d {
	background-position: -16px -44px;
}

.Flag_Flag-es__21cNR {
	background-position: -32px -44px;
}

.Flag_Flag-et__2bCfy {
	background-position: -48px -44px;
}

.Flag_Flag-eu__37I-Q {
	background-position: -64px -44px;
}

.Flag_Flag-fi__WsKaF {
	background-position: -80px -44px;
}

.Flag_Flag-fj__3vG0s {
	background-position: -96px -44px;
}

.Flag_Flag-fk__2j_1D {
	background-position: -112px -44px;
}

.Flag_Flag-fm__2qX90 {
	background-position: -128px -44px;
}

.Flag_Flag-fo__1YUSq {
	background-position: -144px -44px;
}

.Flag_Flag-fr__33wkW {
	background-position: -160px -44px;
}

.Flag_Flag-ga__2i5kX {
	background-position: -176px -44px;
}

.Flag_Flag-gb__1HdSo {
	background-position: -192px -44px;
}

.Flag_Flag-gd__3sQXu {
	background-position: -208px -44px;
}

.Flag_Flag-ge__2HRKd {
	background-position: -224px -44px;
}

.Flag_Flag-gf__2mDSs {
	background-position: -240px -44px;
}

.Flag_Flag-gg__332rz {
	background-position: 0 -55px;
}

.Flag_Flag-gh__1abnw {
	background-position: -16px -55px;
}

.Flag_Flag-gi__2tzix {
	background-position: -32px -55px;
}

.Flag_Flag-gl__19YE9 {
	background-position: -48px -55px;
}

.Flag_Flag-gm__1Tr9l {
	background-position: -64px -55px;
}

.Flag_Flag-gn__7mBkZ {
	background-position: -80px -55px;
}

.Flag_Flag-gp__18m5g {
	background-position: -96px -55px;
}

.Flag_Flag-gq__2MfGh {
	background-position: -112px -55px;
}

.Flag_Flag-gr__1GQnh {
	background-position: -128px -55px;
}

.Flag_Flag-gs__10dBy {
	background-position: -144px -55px;
}

.Flag_Flag-gt__3Ng05 {
	background-position: -160px -55px;
}

.Flag_Flag-gu__3sDbW {
	background-position: -176px -55px;
}

.Flag_Flag-gw__1EDk_ {
	background-position: -192px -55px;
}

.Flag_Flag-gy__2qxeW {
	background-position: -208px -55px;
}

.Flag_Flag-hk__2ee6u {
	background-position: -224px -55px;
}

.Flag_Flag-hm__zKXmb {
	background-position: -240px -55px;
}

.Flag_Flag-hn__cl1ve {
	background-position: 0 -66px;
}

.Flag_Flag-hr__1iycY {
	background-position: -16px -66px;
}

.Flag_Flag-ht__WHYEB {
	background-position: -32px -66px;
}

.Flag_Flag-hu__3Bfd3 {
	background-position: -48px -66px;
}

.Flag_Flag-ic__14Cy6 {
	background-position: -64px -66px;
}

.Flag_Flag-id__1tegV {
	background-position: -80px -66px;
}

.Flag_Flag-ie__1KWlO {
	background-position: -96px -66px;
}

.Flag_Flag-il__3JSnv {
	background-position: -112px -66px;
}

.Flag_Flag-im__HLWq0 {
	background-position: -128px -66px;
}

.Flag_Flag-in__BaSdt {
	background-position: -144px -66px;
}

.Flag_Flag-io__30_Aq {
	background-position: -160px -66px;
}

.Flag_Flag-iq__2f_my {
	background-position: -176px -66px;
}

.Flag_Flag-ir__Pg3VJ {
	background-position: -192px -66px;
}

.Flag_Flag-is__1FOB1 {
	background-position: -208px -66px;
}

.Flag_Flag-it__34kH3 {
	background-position: -224px -66px;
}

.Flag_Flag-je__guTDI {
	background-position: -240px -66px;
}

.Flag_Flag-jm__LgqA7 {
	background-position: 0 -77px;
}

.Flag_Flag-jo__DAkXh {
	background-position: -16px -77px;
}

.Flag_Flag-jp__2d2nS {
	background-position: -32px -77px;
}

.Flag_Flag-ke__1PwtP {
	background-position: -48px -77px;
}

.Flag_Flag-kg__3UQKE {
	background-position: -64px -77px;
}

.Flag_Flag-kh__2a_Fw {
	background-position: -80px -77px;
}

.Flag_Flag-ki__2WnDc {
	background-position: -96px -77px;
}

.Flag_Flag-km__3mwq8 {
	background-position: -112px -77px;
}

.Flag_Flag-kn__2Pded {
	background-position: -128px -77px;
}

.Flag_Flag-kp__2AKgc {
	background-position: -144px -77px;
}

.Flag_Flag-kr__1iC9p {
	background-position: -160px -77px;
}

.Flag_Flag-kurdistan__-kEl0 {
	background-position: -176px -77px;
}

.Flag_Flag-kw__2Jzgo {
	background-position: -192px -77px;
}

.Flag_Flag-ky__2-_OF {
	background-position: -208px -77px;
}

.Flag_Flag-kz__feK1o {
	background-position: -224px -77px;
}

.Flag_Flag-la__3qra- {
	background-position: -240px -77px;
}

.Flag_Flag-lb__2rjPR {
	background-position: 0 -88px;
}

.Flag_Flag-lc__6O0QY {
	background-position: -16px -88px;
}

.Flag_Flag-li__2HM0e {
	background-position: -32px -88px;
}

.Flag_Flag-lk__S6KWK {
	background-position: -48px -88px;
}

.Flag_Flag-lr__3W4LI {
	background-position: -64px -88px;
}

.Flag_Flag-ls__39T7E {
	background-position: -80px -88px;
}

.Flag_Flag-lt__2EJyA {
	background-position: -96px -88px;
}

.Flag_Flag-lu__auNvd {
	background-position: -112px -88px;
}

.Flag_Flag-lv__1L7ka {
	background-position: -128px -88px;
}

.Flag_Flag-ly__3YE9o {
	background-position: -144px -88px;
}

.Flag_Flag-ma__mkqYM {
	background-position: -160px -88px;
}

.Flag_Flag-mc__3t-EY {
	background-position: -176px -88px;
}

.Flag_Flag-md__MQ7KQ {
	background-position: -192px -88px;
}

.Flag_Flag-me__3sJ_o {
	background-position: -208px -88px;
}

.Flag_Flag-mg__2Xsm1 {
	background-position: -224px -88px;
}

.Flag_Flag-mh__To6O0 {
	background-position: -240px -88px;
}

.Flag_Flag-mk__Imo8X {
	background-position: 0 -99px;
}

.Flag_Flag-ml__1ddwp {
	background-position: -16px -99px;
}

.Flag_Flag-mm__1FpGb {
	background-position: -32px -99px;
}

.Flag_Flag-mn__30XJB {
	background-position: -48px -99px;
}

.Flag_Flag-mo__2AfYQ {
	background-position: -64px -99px;
}

.Flag_Flag-mp__mUjHa {
	background-position: -80px -99px;
}

.Flag_Flag-mq__8EkWL {
	background-position: -96px -99px;
}

.Flag_Flag-mr__1vxfc {
	background-position: -112px -99px;
}

.Flag_Flag-ms__1_0td {
	background-position: -128px -99px;
}

.Flag_Flag-mt__12wuN {
	background-position: -144px -99px;
}

.Flag_Flag-mu__1FrYD {
	background-position: -160px -99px;
}

.Flag_Flag-mv__1P1D9 {
	background-position: -176px -99px;
}

.Flag_Flag-mw__2ZgxX {
	background-position: -192px -99px;
}

.Flag_Flag-mx__2Y89z {
	background-position: -208px -99px;
}

.Flag_Flag-my__3KMZf {
	background-position: -224px -99px;
}

.Flag_Flag-mz__2w_5e {
	background-position: -240px -99px;
}

.Flag_Flag-na__36tyS {
	background-position: 0 -110px;
}

.Flag_Flag-nc__3f8oN {
	background-position: -16px -110px;
}

.Flag_Flag-ne__2lwVf {
	background-position: -32px -110px;
}

.Flag_Flag-nf__3LMGA {
	background-position: -48px -110px;
}

.Flag_Flag-ng__14FXX {
	background-position: -64px -110px;
}

.Flag_Flag-ni__2ntgf {
	background-position: -80px -110px;
}

.Flag_Flag-nl__2SEZK {
	background-position: -96px -110px;
}

.Flag_Flag-no__2dFAQ {
	background-position: -112px -110px;
}

.Flag_Flag-np__2Rv6i {
	background-position: -128px -110px;
}

.Flag_Flag-nr__1CKXV {
	background-position: -144px -110px;
}

.Flag_Flag-nu__ziTvl {
	background-position: -160px -110px;
}

.Flag_Flag-nz__cQymY {
	background-position: -176px -110px;
}

.Flag_Flag-om__q_j1J {
	background-position: -192px -110px;
}

.Flag_Flag-pa__37crC {
	background-position: -208px -110px;
}

.Flag_Flag-pe__eJiW3 {
	background-position: -224px -110px;
}

.Flag_Flag-pf__172tZ {
	background-position: -240px -110px;
}

.Flag_Flag-pg__2N5Va {
	background-position: 0 -121px;
}

.Flag_Flag-ph__2_FBr {
	background-position: -16px -121px;
}

.Flag_Flag-pk__LnS27 {
	background-position: -32px -121px;
}

.Flag_Flag-pl__1_VdM {
	background-position: -48px -121px;
}

.Flag_Flag-pm__2QRCb {
	background-position: -64px -121px;
}

.Flag_Flag-pn__28e9g {
	background-position: -80px -121px;
}

.Flag_Flag-pr__3TkEV {
	background-position: -96px -121px;
}

.Flag_Flag-ps__3X0Wk {
	background-position: -112px -121px;
}

.Flag_Flag-pt__3ylEr {
	background-position: -128px -121px;
}

.Flag_Flag-pw__Apmqr {
	background-position: -144px -121px;
}

.Flag_Flag-py__ug1tx {
	background-position: -160px -121px;
}

.Flag_Flag-qa__1Ovkg {
	background-position: -176px -121px;
}

.Flag_Flag-re__1EUNh {
	background-position: -192px -121px;
}

.Flag_Flag-ro__1IEDj {
	background-position: -208px -121px;
}

.Flag_Flag-rs__3ju3k {
	background-position: -224px -121px;
}

.Flag_Flag-ru__hY5tL {
	background-position: -240px -121px;
}

.Flag_Flag-rw__1TAH7 {
	background-position: 0 -132px;
}

.Flag_Flag-sa__1yJTN {
	background-position: -16px -132px;
}

.Flag_Flag-sb__XPVnG {
	background-position: -32px -132px;
}

.Flag_Flag-sc__2jp4M {
	background-position: -48px -132px;
}

.Flag_Flag-scotland__2Ld4j {
	background-position: -64px -132px;
}

.Flag_Flag-sd__1gtq6 {
	background-position: -80px -132px;
}

.Flag_Flag-se__1b92P {
	background-position: -96px -132px;
}

.Flag_Flag-sg__3Uk6_ {
	background-position: -112px -132px;
}

.Flag_Flag-sh__3yv-p {
	background-position: -128px -132px;
}

.Flag_Flag-si__ghFAY {
	background-position: -144px -132px;
}

.Flag_Flag-sj__1LfI5 {
	background-position: -160px -132px;
}

.Flag_Flag-sk__3dSUJ {
	background-position: -176px -132px;
}

.Flag_Flag-sl__-p4wM {
	background-position: -192px -132px;
}

.Flag_Flag-sm__1Aulf {
	background-position: -208px -132px;
}

.Flag_Flag-sn__ihZzq {
	background-position: -224px -132px;
}

.Flag_Flag-so__2yCR3 {
	background-position: -240px -132px;
}

.Flag_Flag-somaliland__PmhO0 {
	background-position: 0 -143px;
}

.Flag_Flag-sr__1Owix {
	background-position: -16px -143px;
}

.Flag_Flag-ss__37akL {
	background-position: -32px -143px;
}

.Flag_Flag-st__1mkpJ {
	background-position: -48px -143px;
}

.Flag_Flag-sv__3CRDM {
	background-position: -64px -143px;
}

.Flag_Flag-sx__3jxVL {
	background-position: -80px -143px;
}

.Flag_Flag-sy__1kGnT {
	background-position: -96px -143px;
}

.Flag_Flag-sz__7T8gj {
	background-position: -112px -143px;
}

.Flag_Flag-tc__1YISz {
	background-position: -128px -143px;
}

.Flag_Flag-td__fmrVF {
	background-position: -144px -143px;
}

.Flag_Flag-tf__1a8Rl {
	background-position: -160px -143px;
}

.Flag_Flag-tg__27StP {
	background-position: -176px -143px;
}

.Flag_Flag-th__3q-SZ {
	background-position: -192px -143px;
}

.Flag_Flag-tibet__rwdlx {
	background-position: -208px -143px;
}

.Flag_Flag-tj__35X76 {
	background-position: -224px -143px;
}

.Flag_Flag-tk__3RJ5X {
	background-position: -240px -143px;
}

.Flag_Flag-tl__2J-kD {
	background-position: 0 -154px;
}

.Flag_Flag-tm__249hf {
	background-position: -16px -154px;
}

.Flag_Flag-tn__1fgEG {
	background-position: -32px -154px;
}

.Flag_Flag-to__3h9oM {
	background-position: -48px -154px;
}

.Flag_Flag-tr__sbyAH {
	background-position: -64px -154px;
}

.Flag_Flag-tt__evd4l {
	background-position: -80px -154px;
}

.Flag_Flag-tv__D2_fm {
	background-position: -96px -154px;
}

.Flag_Flag-tw__1g1kv {
	background-position: -112px -154px;
}

.Flag_Flag-tz__3L1tK {
	background-position: -128px -154px;
}

.Flag_Flag-ua__3XU2- {
	background-position: -144px -154px;
}

.Flag_Flag-ug__16-57 {
	background-position: -160px -154px;
}

.Flag_Flag-um__2hV8q {
	background-position: -176px -154px;
}

.Flag_Flag-us__3OHKd {
	background-position: -192px -154px;
}

.Flag_Flag-uy__1UGei {
	background-position: -208px -154px;
}

.Flag_Flag-uz__2uslC {
	background-position: -224px -154px;
}

.Flag_Flag-va__1oMDe {
	background-position: -240px -154px;
}

.Flag_Flag-vc__124pm {
	background-position: 0 -165px;
}

.Flag_Flag-ve__21hcm {
	background-position: -16px -165px;
}

.Flag_Flag-vg__3mg-W {
	background-position: -32px -165px;
}

.Flag_Flag-vi__1yuTc {
	background-position: -48px -165px;
}

.Flag_Flag-vn__2wLes {
	background-position: -64px -165px;
}

.Flag_Flag-vu__1qDIV {
	background-position: -80px -165px;
}

.Flag_Flag-wales__1ZRai {
	background-position: -96px -165px;
}

.Flag_Flag-wf__3LauQ {
	background-position: -112px -165px;
}

.Flag_Flag-ws__3i9s3 {
	background-position: -128px -165px;
}

.Flag_Flag-xk__3RxXX {
	background-position: -144px -165px;
}

.Flag_Flag-ye__SG9ds {
	background-position: -160px -165px;
}

.Flag_Flag-yt__W9LPg {
	background-position: -176px -165px;
}

.Flag_Flag-za__ai-Re {
	background-position: -192px -165px;
}

.Flag_Flag-zanzibar__iw5OV {
	background-position: -208px -165px;
}

.Flag_Flag-zm__3_hlK {
	background-position: -224px -165px;
}

.Flag_Flag-zw__2vV_9 {
	background-position: -240px -165px;
}

.kR7fzM0DbMo_P5BY-brYn {
    width: 300px;
    max-width: 300px;
}

._32IZyaKWHSUFKmTNHo9k80 {
    width: 350px;
    max-width: 350px;
    margin: 0 12px;
}

._3zAKp4cBzGVNo-VQXbaakx {
    font-family: SegoeWPLight, Arial, sans-serif;
    font-size: 27px;
    font-weight: normal;
    padding: 0 0 10px;
    margin: 30px 0 10px;
    border-bottom: 1px solid #e8e8e8;
}

._3zAKp4cBzGVNo-VQXbaakx > a {
    text-decoration: none;
    color: #0069c4;
}

._1HHOOKuW15ubiARQXcLkuU dl {
    margin: 0;
}

._1HHOOKuW15ubiARQXcLkuU dt {
    font-size: 11px;
    margin: 15px 0 0;
    color: #bcbcbc;
    text-transform: uppercase;
}

._1HHOOKuW15ubiARQXcLkuU dd {
    font-size: 15px;
    word-wrap: break-word;
    /*padding-top: 8px;*/
    height: 20px;
    margin: 8px 0 15px;
}

._12mu51BLISrk1ETbQfie4r {
    width: 100%;
}

._12mu51BLISrk1ETbQfie4r th {
    font-weight: bold;
    font-family: SegoeWPBold, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 8px 0;
    border-top: 8px solid #d9d9d9;
    border-bottom: 8px solid #d9d9d9;
    border-right: 1px solid #a6a6a6;
    background-color: #d9d9d9;
}

._12mu51BLISrk1ETbQfie4r th:last-child {
    border-right: 0;
}

._12mu51BLISrk1ETbQfie4r td {
    box-sizing: unset;
    font-size: 11px;
    border-bottom: 1px solid #dadada;
    border-right: 1px solid #f4f4f4;
    padding: 8px 8px;
    vertical-align: top;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    text-transform: uppercase;
    height: 36px;
}

._12mu51BLISrk1ETbQfie4r td:last-child {
    border-right: none;
}

._12mu51BLISrk1ETbQfie4r .title {
    font-weight: bold;
}

._1KGHbYFon1BgNhC5kSlgkY {
    font-weight: normal;
}

._1KGHbYFon1BgNhC5kSlgkY .comma {
    margin-right: 3px;
}

._1KGHbYFon1BgNhC5kSlgkY span:last-child .comma {
    display: none;
}

._1KGHbYFon1BgNhC5kSlgkY .hovered {
    background: #cccc99;
}

._12mu51BLISrk1ETbQfie4r td.share {
    text-align: center;
    vertical-align: middle;
}

._12mu51BLISrk1ETbQfie4r th.share {
    /*width: 40px*/
}

._12mu51BLISrk1ETbQfie4r th.rightholder {
    width: 80%
}

._12mu51BLISrk1ETbQfie4r tr.remote {
    background: #ffcc99;
}

._12mu51BLISrk1ETbQfie4r tr.local {
    background: #ccff33;
}

._12mu51BLISrk1ETbQfie4r tr.hovered {
    background: #eeeecc;
}

._12mu51BLISrk1ETbQfie4r .remote.hovered {
    background: #FF6600;
}

._12mu51BLISrk1ETbQfie4r .local.hovered {
    background: #99cc00;
}

._12mu51BLISrk1ETbQfie4r .share input {
    width: 25px
}

._2UGV0l4jlAAsd0e1osBfOD {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;
}

._2UGV0l4jlAAsd0e1osBfOD i {
    padding-left: 5px;
    font-size: 14px;
}
._2jjY9FSXZMjKShCdTMyODt {
    width: 300px;
    max-width: 300px;
}

._3tyKvmn68cM8QDWj-HDjhR {
    width: 350px;
    max-width: 350px;
    margin: 0 12px;
}

._2gPByyFbpsorDvshi-0d5Y {
    font-family: SegoeWPLight, Arial, sans-serif;
    font-size: 27px;
    font-weight: normal;
    padding: 0 0 10px;
    margin: 30px 0 10px;
    border-bottom: 1px solid #e8e8e8;
}

._2gPByyFbpsorDvshi-0d5Y > a {
    text-decoration: none;
    color: #0069c4;
}

.LSFxUYH5w5p5trgoSQbsr dl {
    margin: 0;
}

.LSFxUYH5w5p5trgoSQbsr dt {
    font-size: 11px;
    margin: 15px 0 0;
    color: #bcbcbc;
    text-transform: uppercase;
}

.LSFxUYH5w5p5trgoSQbsr dd {
    font-size: 15px;
    word-wrap: break-word;
    /*padding-top: 8px;*/
    height: 20px;
    margin: 8px 0 15px;
}

._2LR1eqeDvW2dwyNZ0iY5Fi {
    width: 100%;
}

._2LR1eqeDvW2dwyNZ0iY5Fi th {
    font-weight: bold;
    font-family: SegoeWPBold, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 8px 0;
    border-top: 8px solid #d9d9d9;
    border-bottom: 8px solid #d9d9d9;
    border-right: 1px solid #a6a6a6;
    background-color: #d9d9d9;
}

._2LR1eqeDvW2dwyNZ0iY5Fi th:last-child {
    border-right: 0;
}

._2LR1eqeDvW2dwyNZ0iY5Fi td {
    box-sizing: unset;
    font-size: 11px;
    border-bottom: 1px solid #dadada;
    border-right: 1px solid #f4f4f4;
    padding: 8px 8px;
    vertical-align: top;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    text-transform: uppercase;
    height: 36px;
}

._2LR1eqeDvW2dwyNZ0iY5Fi td:last-child {
    border-right: none;
}

._2LR1eqeDvW2dwyNZ0iY5Fi .title {
    font-weight: bold;
}

.BX43AroxXa32TiPkm1zJ7 {
    font-weight: normal;
}

.BX43AroxXa32TiPkm1zJ7 .comma {
    margin-right: 3px;
}

.BX43AroxXa32TiPkm1zJ7 span:last-child .comma {
    display: none;
}

.BX43AroxXa32TiPkm1zJ7 .hovered {
    background: #cccc99;
}

._2LR1eqeDvW2dwyNZ0iY5Fi td.share {
    text-align: center;
    vertical-align: middle;
}

._2LR1eqeDvW2dwyNZ0iY5Fi th.share {
    /*width: 40px*/
}

._2LR1eqeDvW2dwyNZ0iY5Fi th.rightholder {
    width: 80%
}

._2LR1eqeDvW2dwyNZ0iY5Fi tr.append {
    background: #ffcc99;
}

._2LR1eqeDvW2dwyNZ0iY5Fi tr.confirm {
    background: #ccff33;
}

._2LR1eqeDvW2dwyNZ0iY5Fi tr.hovered {
    background: #eeeecc;
}

._2LR1eqeDvW2dwyNZ0iY5Fi .append.hovered {
    background: #FF6600;
}

._2LR1eqeDvW2dwyNZ0iY5Fi .confirm.hovered {
    background: #99cc00;
}

._2LR1eqeDvW2dwyNZ0iY5Fi .share input {
    width: 25px
}

._15P8UIHEahjLiac23aXuT {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;
}

._15P8UIHEahjLiac23aXuT i {
    padding-left: 5px;
    font-size: 14px;
}

._1vxC1fm74RPQ1VxlGpXkUT input {
    display: none;
}

._1vxC1fm74RPQ1VxlGpXkUT.edit input {
    display: block;
}

._1vxC1fm74RPQ1VxlGpXkUT.edit span {
    display: none;
}

._1vxC1fm74RPQ1VxlGpXkUT.edit button {
    display: none;
}
._1l2jWQw8OKpC-yVO5s_BfS {
    max-width: 980px;
    width: 980px;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    background-color: #FFF;
}

._2i0rQ1GZLPZBHEQOkH-JIA th {
    height: 15px;
}

._361EPiAWUZhca0kzn78DSG th {
    height: 48px;
}
._20jlbokGdhWZJd1DABYWit {
    position: relative;
    display: flex;
    max-width: 960px;
    width: 960px;
    margin: auto;
    overflow: hidden;
    border: 1px solid #000;
}

._27GTbJpTzFZze1pvkqjq0n {
    width: 100%;
    overflow: auto;
}

._20jlbokGdhWZJd1DABYWit table {
    width: 100%;
    /*margin:auto;*/
    border-collapse: separate;
    border-spacing: 0;
}

._20jlbokGdhWZJd1DABYWit th, ._20jlbokGdhWZJd1DABYWit td {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    white-space: nowrap;
    vertical-align: top;
}

._20jlbokGdhWZJd1DABYWit thead, ._20jlbokGdhWZJd1DABYWit tfoot {
    background: #f9f9f9;
}

._1tJHcWxCg0YHS5jw9UiY1Q {
    height: 90px;
    overflow: hidden;
    display: flex;
    max-width: 960px;
    width: 960px;
    margin: auto;
}

._2yDABUYIp4Zgq3jmDSUqvV {
}

._3Y6vLSWcvpIAEQZuZanrO8 {
    height: 90px;
}

._9M03pP3TIDT6vKS0r81L2 {
    position: fixed;
    top: 0;
    display: flex;
    width: 960px;
    overflow: hidden;
}

._3mq-r6G1YwpbFq0TqV7kkR {
	width: 880px;
}

._3mq-r6G1YwpbFq0TqV7kkR button {
  border: none;
  outline: none;
	cursor: pointer;
}

._3mq-r6G1YwpbFq0TqV7kkR h2 {
  border: none;
	margin-bottom: 0;
}

.YD6BLTiOBVS_Pp6BgqUIz {
  border-spacing: 0;
	border-collapse: separate;
}

._2a7-taC_PuVBmUk9hAGVfR {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
	padding: 0;
}

.ngH8QFDemTputxfY7B0-3 th {
  font-weight: normal;
  font-family: SegoeWPBold, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  padding: 0 8px 0;
  border-top: 8px solid #cecece;
  border-bottom: 8px solid #cecece;
  border-right: 1px solid #a6a6a6;
	background-color: #cecece;
}

.ngH8QFDemTputxfY7B0-3 th:last-child {
	border-right: none;
}

._18yxldVLS_oqbM6z8-w8zF td {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #e5e5e5;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  width: 1px;
  white-space: nowrap;
  height: 35px;
	vertical-align: top;
}

._18yxldVLS_oqbM6z8-w8zF td:first-child {
	border-right: none;
}

.T38uhDWa-sPWkVlQUx5Ib td:first-child {
	text-align: center;
}

._3B-F3Ak0e0OdQ1filCQe-F td {
  border: none;
  border-bottom: 2px solid #cecece;
	font-size: 16px;
}

._3tkOuBwxEznmwPn7gb9T6K {
  color: #808080;
	padding-top: 14px;
}

._3tkOuBwxEznmwPn7gb9T6K[data-invalid=true] {
	color: var(--err-color);
}

.afIWcfrMQ6xcEGlwN8SZv {
  height: 48px;
	background-color: transparent;
}

.afIWcfrMQ6xcEGlwN8SZv i {
  font-size: 20px;
	margin-right: 5px;
}

.afIWcfrMQ6xcEGlwN8SZv span {
	font-size: 12px;
}

.afIWcfrMQ6xcEGlwN8SZv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
	background-color: #3791ef;
}

.afIWcfrMQ6xcEGlwN8SZv:hover {
	background-color: #0069c4;
}

._3nw-AdfSTvKJTCt-VJRd87 {
  color: #006fe3;
  font-size: 14px;
  background-color: transparent;
  height: 45px;
	margin-left: 7px;
}

._1mTMQ11LUTvTuxMdupI8Fq {
  width: 35px;
  height: 35px;
  font-size: 20px;
  color: var(--err-color);
  background-color: transparent;
	padding: 0 5px;
}

._1mTMQ11LUTvTuxMdupI8Fq:hover {
	color: #000000;
}

._3aKo5fXY9NcQfby0FE63h- ul {
  list-style: none;
	padding: 0 0 0 10px;
}

._3aKo5fXY9NcQfby0FE63h- i.error {
	color: var(--err-color);
}

._3O2xsmGoBb9WNxDBjo8Fy6 {
	display: flex;
	flex-direction: column;
	min-width: 310px;
	min-height: 33px;
	background: white;
	text-decoration: none;
	color: #555;
	border: 1px solid #d1d1d1;
	border-radius: 2px;
	font-size: 14px;
	font-weight: 600;
	-webkit-transition: border .125s ease, background .125s ease;
	transition: border .125s ease, background .125s ease;
}

._34Z1H000pQKihFWb_fG6Vz {
	list-style: none;
	margin: 0;
	padding: 0;
}

.VCXktYkUjYqm_irXIpwIR {
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	min-height: 20px;
	padding: 5px;
}

.VCXktYkUjYqm_irXIpwIR:hover {
	background-color: #e5e6e7;
	color: #000;
}

.DgU5e82mT8lDlWNG-nvTF {
	margin: 4px 6px;
}

._10FTnB6oappHE4F1Orl9QV {
	position: absolute;
	border: 1px solid #e5e6e7;
	background-color: #FFF;
	overflow-y: scroll;
	max-height: 300px;
	z-index: 9999;
}

._3INlhW4jfIW8zXsf0QbZQZ {
	height: 21px;
	width: 25px;
	box-sizing: content-box;
	line-height: 18px;
	margin: 1px 1px 1px 5px;
	outline: none;
	border: none;
}

._3NvU1q85cbB5Nbqzh-O3qC {
	display: flex;
}

._3NvU1q85cbB5Nbqzh-O3qC:nth-child(2n+2) {
	background-color: #cccccc36;
}

._3NvU1q85cbB5Nbqzh-O3qC > div {
	width: 350px;
	max-width: 350px;
	min-width: 350px;
}

._3z6f7H5oBZHG80VIQA7TWB {
	font-weight: lighter;
	font-style: italic;
}

._1BQhy4Wisy1jWD6fxf-nFU {
	font-size: 11px;
	font-weight: lighter;
}

.pyoKW04LNzGmGaLcM_ieP {
	font-weight: lighter;
	white-space: normal;
}

._3S55sEz_heexR2fz0OCHcR {
	background-color: #c3c3c3;
}

._1vWw092aEzDV_MmAg1-p1j {
  display: flex;
  flex-direction: row;
	flex-wrap: wrap;
}

._1vWw092aEzDV_MmAg1-p1j[data-width='sm'] {
	max-width: 200px;
}

._1vWw092aEzDV_MmAg1-p1j[data-width='md'] {
	max-width: 400px;
}

._1vWw092aEzDV_MmAg1-p1j[data-width='lg'] {
	max-width: 900px;
}

._1FbdBRAZ8I9hPEldIrhvXC {
  display: flex;
  font-family: SegoeWPSemibold, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 1px;
  padding: 3px 8px;
  white-space: nowrap;
  background: white;
  border: 1px solid #e7eaec;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

._24UQuEwfZX6W0wREXwJ2kE {
  padding: 0 0 0 5px;
  color: inherit;
  border: none;
  cursor: pointer;
  background-color: transparent;
	outline: none;
}

._24UQuEwfZX6W0wREXwJ2kE:hover {
	color: #ed5565;
}

._1FbdBRAZ8I9hPEldIrhvXC[data-primary='true'] {
  color: #000000;
  border: none;
	background-color: #d3e5f2;
}

._1FbdBRAZ8I9hPEldIrhvXC[data-primary='true']:hover {
	background-color: #84cbff;
}

._14LtwDxdhitaeOQnY3FbJ8 {
  display: none;
  border-top: 1px solid #e5e6e7;
  list-style: none;
  margin: 0;
	padding: 0;
}

._14LtwDxdhitaeOQnY3FbJ8[data-open=true] {
	display: block;
}

._1HjfLaKfLSo1ueTlDFRZip {
	background: white;
}

._1Jvo-JdBiU_IV_BGE7vZw2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  outline: none;
	border: 1px solid #d1d1d1;
}

._3HLAMm_qbm07FMNTkfF-L0[type=number]::-webkit-inner-spin-button,
._3HLAMm_qbm07FMNTkfF-L0[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
	margin: 0;
}

._3HLAMm_qbm07FMNTkfF-L0 {
  font-size: 14px !important;
  border: none !important;
  padding: 0 0 0 8px !important;
  height: 33px !important;
  outline: none;
  width: 65px;
  text-align: right;
	background-color: transparent;
}

._1Jvo-JdBiU_IV_BGE7vZw2[data-invalid=true] {
	border-color: var(--err-color);
}

.Modal {
    /*display: none;*/
}

.Modal.open {
    /*display:block;*/
}

._27b7eBdShsTkNNu9O_pPMC {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
}

.Modal-appear {
    opacity: 0;
}

.Modal-appear.Modal-appear-active {
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;

}

.styles_loading__3b81Y {
	display: flex;
	justify-content: center;
	padding: 10px 0;
	margin: 25vh 0;
}

.styles_loading__3b81Y.styles_loadingSmall__286DH {
	margin: 25px 0;
}

.styles_loading__3b81Y.styles_loadingFixed__33lL3 {
	position: fixed;
	align-items: center;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.styles_loading__3b81Y.styles_loadingCenter__47Z73 {
	position: fixed;
	align-items: center;
	left: 50%;
	top: 50%;
	z-index: 100;
	margin: 0;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.styles_loading__3b81Y.styles_loadingFullscreen__2ontr {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.9);
	z-index: 9999;
	margin: 0;
}

@-webkit-keyframes styles_spinner__2IEtB {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes styles_spinner__2IEtB {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.styles_image__zExaP {
	width: 35px;
	height: 35px;
	-webkit-animation: styles_spinner__2IEtB 2s linear infinite;
	        animation: styles_spinner__2IEtB 2s linear infinite;
}

@media (min-width: 1024px) {
	.styles_loading__3b81Y {
		margin: 10vh 0;
	}
}

.ReportTable_container__DQOti {
	border-collapse: separate;
	border-spacing: 0;
	display: grid;
	grid-template-columns: auto 270px;
	grid-template-areas: "table controls";
	font-size: 11px;
	color: #c0c0c0;
	overflow: hidden;
	height: 498px;
}

.ReportTable_container__DQOti * {
	box-sizing: border-box;
}

.ReportTable_container__DQOti .sticky-table-cell {
	border-bottom: 1px solid #dadada;
	border-right: 1px solid #f4f4f4;
	/*min-height: 50px;*/
}

.ReportTable_container__DQOti .sticky-table .x-scrollbar {
	width: calc(100% - 18px);
}

.ReportTable_scroll__1ZMbJ {
	overflow-x: auto;
	border-top: 2px solid #d9d9d9;
	grid-area: table;
	height: 500px;
}

.ReportTable_cell__32rJx {
	height: 100%;
	padding: 8px;
	overflow-wrap: break-spaces;
	white-space: normal;
	word-break: break-word;
	text-transform: none;
	font-family: var(--regular-font);
	color: #000;
	cursor: pointer;
	vertical-align: top;
}

.ReportTable_right__qUT0P {
	text-align: end;
}

.ReportTable_headerCell__1dYPT {
	border-right: 1px solid #e6e6e6 !important;
	vertical-align: middle;
	background-color: #fff;
}

.ReportTable_binded__3Z-c0 {
	background-color: #ecffe8;
}

.ReportTable_selected__38ILD {
	background-color: #e8f7ff;
}

.ReportTable_header__2hy5v {
	text-align: center;
	/*height: 100%;*/
	min-width: 120px;
	overflow-wrap: break-spaces;
	white-space: normal;
	padding: 5px 5px;
	/*font-weight: bold;*/
	text-transform: uppercase;
	color: #000;
	font-family: var(--bold-font);
	word-break: normal;
	word-wrap: normal;
}

@supports (word-break: break-word) {
	.ReportTable_header__2hy5v {
		word-break: break-word;
	}
}

.ReportTable_headerNotLinked__3NUmJ {
	color: #e6e6e6;
}

.ReportTable_subHeader__8Q5zK {
	border: none !important;
	cursor: pointer;
}

.ReportTable_subHeaderContent__zTESw {
	background-color: #DBDBDB;
	font-weight: normal;
	font-family: var(--bold-font);
	/*display: flex;*/
	text-align: center;
	/*justify-content: center;*/
	/*flex-direction: column;*/
	max-height: 33px;
	font-size: 11px;
	text-transform: uppercase;
	height: 33px;
	border-bottom: 8px solid transparent;
	border-top: 8px solid transparent;
	padding: 2px 0 2px 0;
	position: relative;
	color: #000;
}

.ReportTable_subHeaderContent__zTESw span {
	border-left: 1px solid #e6e6e6;
	border-right: 1px solid #e6e6e6;
	display: block;
}

.ReportTable_subHeaderSelected__C18E- {
	font-weight: bold;
	/*display: block !important;*/
	/*height: 20px;*/
}

.ReportTable_subHeaderSelected__C18E- .ReportTable_subHeaderContent__zTESw {
	/*background-color: #DBDBDB;*/
	/*border-bottom: 8px solid transparent ;*/
	/*border-top: 8px solid transparent;*/

}

.ReportTable_subHeaderSelected__C18E- .ReportTable_subHeaderContent__zTESw::before {
	top: -9px;
	left: 50%;
	border: solid 5px transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	border-top-color: #e5f4ff;
	margin-left: -5px;
}

.ReportTable_subHeaderSelected__C18E- .ReportTable_subHeaderContent__zTESw::after {
	bottom: -9px;
	left: 50%;
	border: solid 5px transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	border-bottom-color: #e5f4ff;
	margin-left: -5px;
}

.ReportTable_leftColumn__1q-tD {
	height: 100%;
	/*width: 100%;*/
	padding: 8px;
	/*display: flex;*/
	/*align-items: center;*/
	color: #000;
	font-family: var(--bold-font);
}

.sticky-table-corner .ReportTable_left__37y_d {
	border: none !important;
}

.sticky-table-corner .sticky-table-row:first-child .sticky-table-cell {
	border-right: 1px solid #e6e6e6 !important;
}

.sticky-table-row .ReportTable_cell__32rJx:last-child div {
	margin-right: 10px;
}

.ReportTable_leftColumn__1q-tD div {
	float: right;
}

.ReportTable_deletedRow__3QwhA {
	background-color: #ebebeb;
	opacity: .5;
	color: #aeaeae;
}

.ReportTable_left__37y_d {
	background-color: #DBDBDB;
	border-bottom: 1px solid #f4f4f4 !important;
	opacity: 1;
	min-width: 55px;
}

.ReportTable_removeButton__21aRI {
	background-color: transparent;
	width: 22px;
	/* height: 30px; */
	float: left;
	border: none;
	margin: 0;
	margin-top: -2px;
	padding: 0;
	font-size: 16px;
	cursor: pointer;
	color: #c0c0c0;
	outline: none;
}

.ReportTable_removeButton__21aRI:hover {
	color: #000;
}

.ReportTable_deletedRow__3QwhA .ReportTable_removeButton__21aRI {
	color: var(--err-color);
}

.ReportTable_deletedRow__3QwhA .ReportTable_leftColumn__1q-tD {
	color: #aeaeae;
}

.ReportTable_controls__1AId4 {
	display: flex;
	grid-area: controls;
	flex-direction: column;
	padding: 15px 20px 0;
	margin-left: 20px;
	height: 500px;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #e6e6e6;
	color: #000;
}

.ReportTable_controls__1AId4 label::after {
	content: none;
}

.ReportTable_controls__1AId4 input {
	margin: 0 8px 0 0;
}

.ReportTable_controls__1AId4 h2 {
	font-size: 11px;
	font-weight: bold;
	margin: 0 0 10px;
	padding: 0;
	text-transform: uppercase;
}

.ReportTable_controls__1AId4 .items {
	padding-bottom: 15px;
}

.ReportTable_label__1cva4 {

}

.ReportTable_filter__1X7zc {
	display: flex;
	align-items: start;
	margin-bottom: 5px;
}

.ReportTable_filter__1X7zc label {
	font-family: var(--regular-font);
	color: #000;
	text-transform: none;
}

.ReportTable_controls__1AId4 div:first-child .ReportTable_filter__1X7zc:first-child {
	margin-bottom: 15px;
}

label.ReportTable_disabled__2gjzG {
	color: #ccc;
}

.ReportTable_errors__3kEpY {
	display: none;
	width: calc(100% - 20px);
	padding: 20px;
	border: 1px solid var(--err-color);
	margin: 0 20px 15px;
}

.ReportTable_errors__3kEpY.show {
	display: flex;
}

.ReportTable_errors__3kEpY i {
	color: var(--err-color);
	margin-right: 10px;
}

.ReportTable_error__28XKG {
	height: 498px;
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	border: 1px solid #ccc;
}

.ReportTable_error__28XKG span {
	max-width: 70%;
}

.ReportTable_error__28XKG i {
	color: var(--err-color);
	font-size: 40px;
	margin-right: 20px;
}

.ReportTable_deletedRow__3QwhA.sticky-table-cell {
	background-color: #f0f0f0;
	border-bottom: 1px solid #cecece;
	border-right: 1px solid #e1e1e1;

}


/*Firefox double bottom scrollbar workaround*/
@supports (scrollbar-width: none) {
	.ReportTable_container__DQOti .sticky-table .sticky-table-x-wrapper {
		scrollbar-width: none;
	}
}
.ReportTable_container__14vwX {
	border-collapse: separate;
	border-spacing: 0;
	display: grid;
	grid-template-columns: auto 270px;
	grid-template-areas: "table controls";
	font-size: 11px;
	color: #c0c0c0;
	overflow: hidden;
	height: 498px;
}

.ReportTable_container__14vwX * {
	box-sizing: border-box;
}

.ReportTable_container__14vwX .sticky-table-cell {
	border-bottom: 1px solid #dadada;
	border-right: 1px solid #f4f4f4;
	/*min-height: 50px;*/
}

.ReportTable_container__14vwX .sticky-table .x-scrollbar {
	width: calc(100% - 18px);
}

.ReportTable_scroll__1xvyx {
	overflow-x: auto;
	border-top: 2px solid #d9d9d9;
	grid-area: table;
	height: 500px;
}

.ReportTable_cell__2qCMv {
	height: 100%;
	padding: 8px;
	overflow-wrap: break-spaces;
	white-space: normal;
	word-break: break-word;
	text-transform: none;
	font-family: var(--regular-font);
	color: #000;
	cursor: pointer;
}

.ReportTable_right__2Vz_y {
	text-align: end;
}

.ReportTable_headerCell__14VoS {
	border-right: 1px solid #e6e6e6 !important;
	vertical-align: middle;
}

.ReportTable_binded__3byC9 {
	background-color: #ecffe8;
}

.ReportTable_selected__oB3Qf {
	background-color: #e8f7ff;
}

.ReportTable_header__3tMQ- {
	text-align: center;
	/*height: 100%;*/
	min-width: 120px;
	overflow-wrap: break-spaces;
	white-space: normal;
	padding: 5px 5px;
	/*font-weight: bold;*/
	text-transform: uppercase;
	color: #000;
	font-family: var(--bold-font);
	word-break: normal;
	word-wrap: normal;
}

@supports (word-break: break-word) {
	.ReportTable_header__3tMQ- {
		word-break: break-word;
	}
}

.ReportTable_headerNotLinked__T3bFN {
	color: #e6e6e6;
}

.ReportTable_subHeader__nepYm {
	border: none !important;
	cursor: pointer;
}

.ReportTable_subHeaderContent__1tEvQ {
	background-color: #DBDBDB;
	font-weight: normal;
	font-family: var(--bold-font);
	/*display: flex;*/
	text-align: center;
	/*justify-content: center;*/
	/*flex-direction: column;*/
	max-height: 33px;
	font-size: 11px;
	text-transform: uppercase;
	height: 33px;
	border-bottom: 8px solid transparent;
	border-top: 8px solid transparent;
	padding: 2px 0 2px 0;
	position: relative;
	color: #000;
}

.ReportTable_subHeaderContent__1tEvQ span {
	border-left: 1px solid #e6e6e6;
	border-right: 1px solid #e6e6e6;
	display: block;
}

.ReportTable_subHeaderSelected__2ysx0 {
	font-weight: bold;
	/*display: block !important;*/
	/*height: 20px;*/
}

.ReportTable_subHeaderSelected__2ysx0 .ReportTable_subHeaderContent__1tEvQ {
	/*background-color: #DBDBDB;*/
	/*border-bottom: 8px solid transparent ;*/
	/*border-top: 8px solid transparent;*/

}

.ReportTable_subHeaderSelected__2ysx0 .ReportTable_subHeaderContent__1tEvQ::before {
	top: -9px;
	left: 50%;
	border: solid 5px transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	border-top-color: #e5f4ff;
	margin-left: -5px;
}

.ReportTable_subHeaderSelected__2ysx0 .ReportTable_subHeaderContent__1tEvQ::after {
	bottom: -9px;
	left: 50%;
	border: solid 5px transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	border-bottom-color: #e5f4ff;
	margin-left: -5px;
}

.ReportTable_leftColumn__dvcNs {
	height: 100%;
	/*width: 100%;*/
	padding: 8px;
	/*display: flex;*/
	/*align-items: center;*/
	color: #000;
	font-family: var(--bold-font);
}

.sticky-table-corner .ReportTable_left__pYLZG {
	border: none !important;
}

.sticky-table-corner .sticky-table-row:first-child .sticky-table-cell {
	border-right: 1px solid #e6e6e6 !important;
}

.sticky-table-row .ReportTable_cell__2qCMv:last-child div {
	margin-right: 10px;
}

.ReportTable_leftColumn__dvcNs div {
	float: right;
}

.ReportTable_deletedRow__3lVzG {
	background-color: #ebebeb;
	opacity: .5;
	color: #aeaeae;
}

.ReportTable_left__pYLZG {
	background-color: #DBDBDB;
	border-bottom: 1px solid #f4f4f4 !important;
	opacity: 1;
}

.ReportTable_removeButton__4V_rC {
	background-color: transparent;
	width: 22px;
	/* height: 30px; */
	float: left;
	border: none;
	margin: 0;
	margin-top: -2px;
	padding: 0;
	font-size: 16px;
	cursor: pointer;
	color: #c0c0c0;
	outline: none;
}

.ReportTable_removeButton__4V_rC:hover {
	color: #000;
}

.ReportTable_deletedRow__3lVzG .ReportTable_removeButton__4V_rC {
	color: var(--err-color);
}

.ReportTable_deletedRow__3lVzG .ReportTable_leftColumn__dvcNs {
	color: #aeaeae;
}

.ReportTable_controls__3fjQp {
	display: flex;
	grid-area: controls;
	flex-direction: column;
	padding: 15px 20px 0;
	margin-left: 20px;
	height: 500px;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #e6e6e6;
	color: #000;
}

.ReportTable_controls__3fjQp label::after {
	content: none;
}

.ReportTable_controls__3fjQp input {
	margin: 0 8px 0 0;
}

.ReportTable_controls__3fjQp h2 {
	font-size: 11px;
	font-weight: bold;
	margin: 0 0 10px;
	padding: 0;
	text-transform: uppercase;
}

.ReportTable_controls__3fjQp .items {
	padding-bottom: 15px;
}

.ReportTable_label__3ldAU {

}

.ReportTable_filter__2gdhU {
	display: flex;
	align-items: start;
	margin-bottom: 5px;
}

.ReportTable_filter__2gdhU label {
	font-family: var(--regular-font);
	color: #000;
	text-transform: none;
}

.ReportTable_controls__3fjQp div:first-child .ReportTable_filter__2gdhU:first-child {
	margin-bottom: 15px;
}

label.ReportTable_disabled__lk4Bz {
	color: #ccc;
}

.ReportTable_errors__3kcrC {
	display: none;
	width: calc(100% - 20px);
	padding: 20px;
	border: 1px solid var(--err-color);
	margin: 0 20px 15px;
}

.ReportTable_errors__3kcrC.show {
	display: flex;
}

.ReportTable_errors__3kcrC i {
	color: var(--warn-color);
	margin-right: 10px;
}

.ReportTable_deletedRow__3lVzG.sticky-table-cell {
	background-color: #f0f0f0;
	border-bottom: 1px solid #cecece;
	border-right: 1px solid #e1e1e1;
}
.ReportTable_label__3ldAU span.required {
	color: #3dcc4a;
}

.ReportTable_wizard-controls__3V_OO {
	display: flex;
	justify-content: space-between;
	margin: 30px 0;
}

/*Firefox double bottom scrollbar workaround*/
@supports (scrollbar-width: none) {
	.ReportTable_container__14vwX .sticky-table .sticky-table-x-wrapper {
		scrollbar-width: none;
	}
}
