:local .search-result {
    font-size: 11px;
    text-transform: uppercase;
    /*border: 1px solid #d9d9d9;*/
    /*border-top: none;*/

    display: flex;
    flex-direction: row;
}

:local .search-result-group-items {
    width: 100%;
}

:local .search-result-group-items ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

:local .search-result-group-items li {
    padding: 6px 9px;
    cursor: pointer;
}

:local .search-result-group {
    /*width: 15%;*/
    /*width: 162px;*/
    padding: 6px 9px;
    text-align: center;
    user-select: none;
    background-color: #f5f5f5;
    /*border-right: 1px solid #d9d9d9;*/
}

/*:local  .search-result-group-items li:hover {*/
/*background-color: #cdeaff;*/
/*}*/

:local .search-result-group-items a {
    text-decoration: none;
    color: inherit;
}

:local .search-result-more {
    color: #006fe3;
}

:local .search-result-more a {
    width: 100%;
    height: 100%;
    display: block;
}

:local .search-count {
    background-color: #c1c1c1;
    border-radius: 10px;
    color: white;
    padding: 3px 6px;
    min-width: 20px;
    display: inline-block;
    text-align: center;
}

:local .search-result-category {
    cursor: pointer;
    margin-right: 5px;
    font-size: 13px;
    text-transform: capitalize;
}
