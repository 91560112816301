:local .InputDropdown {
  display: none;
  border-top: 1px solid #e5e6e7;
  list-style: none;
  margin: 0;
	padding: 0;
}

:local .InputDropdown[data-open=true] {
	display: block;
}

:local .Message {
	background: white;
}
