:local .search-results {
    min-height: 0;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid #d9d9d9;
}

:local .search-results :global(._active) {
    background-color: #cdeaff;
}

:local .search-results :global(._selectable) {
    cursor: pointer;
}
