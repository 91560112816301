:global .Modal {
    /*display: none;*/
}

:global .Modal.open {
    /*display:block;*/
}

:local .modal-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
}

:global .Modal-appear {
    opacity: 0;
}

:global .Modal-appear.Modal-appear-active {
    opacity: 1;
    transition: opacity 0.3s;

}
