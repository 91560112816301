:local .TokenGrid {
  display: flex;
  flex-direction: row;
	flex-wrap: wrap;
}

:local .TokenGrid[data-width='sm'] {
	max-width: 200px;
}

:local .TokenGrid[data-width='md'] {
	max-width: 400px;
}

:local .TokenGrid[data-width='lg'] {
	max-width: 900px;
}
