:local .DiffSection {
    width: 300px;
    max-width: 300px;
}

:local .DiffSectionMerged {
    width: 350px;
    max-width: 350px;
    margin: 0 12px;
}

:local .Title {
    font-family: SegoeWPLight, Arial, sans-serif;
    font-size: 27px;
    font-weight: normal;
    padding: 0 0 10px;
    margin: 30px 0 10px;
    border-bottom: 1px solid #e8e8e8;
}

:local .Title > a {
    text-decoration: none;
    color: #0069c4;
}

:local .BlockInfo dl {
    margin: 0;
}

:local .BlockInfo dt {
    font-size: 11px;
    margin: 15px 0 0;
    color: #bcbcbc;
    text-transform: uppercase;
}

:local .BlockInfo dd {
    font-size: 15px;
    word-wrap: break-word;
    /*padding-top: 8px;*/
    height: 20px;
    margin: 8px 0 15px;
}

:local .Table {
    width: 100%;
}

:local .Table th {
    font-weight: bold;
    font-family: SegoeWPBold, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 8px 0;
    border-top: 8px solid #d9d9d9;
    border-bottom: 8px solid #d9d9d9;
    border-right: 1px solid #a6a6a6;
    background-color: #d9d9d9;
}

:local .Table th:last-child {
    border-right: 0;
}

:local .Table td {
    box-sizing: unset;
    font-size: 11px;
    border-bottom: 1px solid #dadada;
    border-right: 1px solid #f4f4f4;
    padding: 8px 8px;
    vertical-align: top;
    transition: 0.2s ease;
    text-transform: uppercase;
    height: 36px;
}

:local .Table td:last-child {
    border-right: none;
}

:local .Table :global .title {
    font-weight: bold;
}

:local .Role {
    font-weight: normal;
}

:local .Role :global .comma {
    margin-right: 3px;
}

:local .Role :global span:last-child .comma {
    display: none;
}

:local .Role :global .hovered {
    background: #cccc99;
}

:local .Table :global td.share {
    text-align: center;
    vertical-align: middle;
}

:local .Table :global th.share {
    /*width: 40px*/
}

:local .Table :global th.rightholder {
    width: 80%
}

:local .Table :global tr.remote {
    background: #ffcc99;
}

:local .Table :global tr.local {
    background: #ccff33;
}

:local .Table :global tr.hovered {
    background: #eeeecc;
}

:local .Table :global .remote.hovered {
    background: #FF6600;
}

:local .Table :global .local.hovered {
    background: #99cc00;
}

:local .Table :global .share input {
    width: 25px
}

:local .Button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    outline: none;
}

:local .Button i {
    padding-left: 5px;
    font-size: 14px;
}