:local .RightHoldersSharesTable {
	width: 880px;
}

:local .RightHoldersSharesTable button {
  border: none;
  outline: none;
	cursor: pointer;
}

:local .RightHoldersSharesTable h2 {
  border: none;
	margin-bottom: 0;
}

:local .Table {
  border-spacing: 0;
	border-collapse: separate;
}

:local .Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
	padding: 0;
}

:local .TableHeader th {
  font-weight: normal;
  font-family: SegoeWPBold, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  padding: 0 8px 0;
  border-top: 8px solid #cecece;
  border-bottom: 8px solid #cecece;
  border-right: 1px solid #a6a6a6;
	background-color: #cecece;
}

:local .TableHeader th:last-child {
	border-right: none;
}

:local .TableBody td {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #e5e5e5;
  transition: 0.2s ease;
  width: 1px;
  white-space: nowrap;
  height: 35px;
	vertical-align: top;
}

:local .TableBody td:first-child {
	border-right: none;
}

:local .ContributorRow td:first-child {
	text-align: center;
}

:local .LastRow td {
  border: none;
  border-bottom: 2px solid #cecece;
	font-size: 16px;
}

:local .ShareSum {
  color: #808080;
	padding-top: 14px;
}

:local .ShareSum[data-invalid=true] {
	color: var(--err-color);
}

:local .RecalculateButton {
  height: 48px;
	background-color: transparent;
}

:local .RecalculateButton i {
  font-size: 20px;
	margin-right: 5px;
}

:local .RecalculateButton span {
	font-size: 12px;
}

:local .RecalculateButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
	background-color: #3791ef;
}

:local .RecalculateButton:hover {
	background-color: #0069c4;
}

:local .AddButton {
  color: #006fe3;
  font-size: 14px;
  background-color: transparent;
  height: 45px;
	margin-left: 7px;
}

:local .RemoveButton {
  width: 35px;
  height: 35px;
  font-size: 20px;
  color: var(--err-color);
  background-color: transparent;
	padding: 0 5px;
}

:local .RemoveButton:hover {
	color: #000000;
}

:local .Errors ul {
  list-style: none;
	padding: 0 0 0 10px;
}

:local .Errors :global i.error {
	color: var(--err-color);
}
