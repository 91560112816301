:local .ShareInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  outline: none;
	border: 1px solid #d1d1d1;
}

:local .Input[type=number]::-webkit-inner-spin-button,
:local .Input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
	margin: 0;
}

:local .Input {
  font-size: 14px !important;
  border: none !important;
  padding: 0 0 0 8px !important;
  height: 33px !important;
  outline: none;
  width: 65px;
  text-align: right;
	background-color: transparent;
}

:local .ShareInput[data-invalid=true] {
	border-color: var(--err-color);
}
