:local .ClaimerLicensorsPeriodDiagram {
    /*display: none;*/
}

:global .claimer-diagram__container {
    font-family: SegoeWPSemibold, Arial, sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    fill: #4d4d4d;
    user-select: none;
}

:global .claimer-diagram__container rect,
:global .claimer-diagram__container line {
    shape-rendering: crispedges;
}

:global .claimer-diagram__container_diagram .period-line {
    /**/
}

:global .claimer-diagram__container_diagram .count {
    stroke: #dae1e6;
}

:global .claimer-diagram__container_diagram .unconfirmed {
    stroke: #9b99ff;
}

:global .claimer-diagram__container_diagram .ignored {
    stroke: #aec7e8;
}

:global .claimer-diagram__container_diagram .identified {
    stroke: #9b99ff;
}

:global .claimer-diagram__container_diagram .rightless {
    stroke: #b3b3b3;
}

:global .claimer-diagram__container_diagram .disputed {
    stroke: #ff4a4a;
}

:global .claimer-diagram__container_diagram .disputed_copublishing {
    stroke: #ff2e46;
}

:global .claimer-diagram__container_diagram .disputed_contribution {
    stroke: #ff8c39;
}

:global .claimer-diagram__container_diagram .claimable {
    stroke: #6ce270;
}

:global .claimer-diagram__container_diagram .claimed {
    stroke: #ffd93e;
}

:global .claimer-diagram__container_diagram .accounted {
    stroke: #7fd2ff;
}

:global .claimer-diagram__container_diagram .unaccounted {
    stroke: #d3eaff;
}

:global .claimer-diagram__container_diagram .withheld {
    stroke: #fa5bff;
}

:global .claimer-diagram__container_diagram circle.count {
    fill: #dae1e6;
}

:global .claimer-diagram__container_diagram circle.unconfirmed {
    fill: #9b99ff;
}

:global .claimer-diagram__container_diagram circle.ignored {
    fill: #aec7e8;
}

:global .claimer-diagram__container_diagram circle.identified {
    fill: #9b99ff;
}

:global .claimer-diagram__container_diagram circle.rightless {
    fill: #b3b3b3;
}

:global .claimer-diagram__container_diagram circle.disputed {
    fill: #ff4a4a;
}

:global .claimer-diagram__container_diagram circle.disputed_copublishing {
    fill: #ff2e46;
}

:global .claimer-diagram__container_diagram circle.disputed_contribution {
    fill: #ff8c39;
}

:global .claimer-diagram__container_diagram circle.claimable {
    fill: #6ce270;
}

:global .claimer-diagram__container_diagram circle.claimed {
    fill: #ffd93e;
}

:global .claimer-diagram__container_diagram circle.accounted {
    fill: #7fd2ff;
}

:global .claimer-diagram__container_diagram circle.unaccounted {
    fill: #d3eaff;
}

:global .claimer-diagram__container_diagram circle.withheld {
    fill: #fa5bff;
}

:global .claimer-diagram__container input[type=checkbox],
:global .claimer-diagram__container input[type=radio] {
    margin: 0;
}

:global .claimer-diagram__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

:global .claimer-diagram__container_diagram {
    width: 580px;
    height: 500px;
    max-width: 580px;
    max-height: 500px;
}

:global .claimer-diagram__container_controls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    width: 180px;
    max-width: 180px;
}

:global .claimer-diagram__container_controls input[type=radio],
:global .claimer-diagram__container_controls input[type=checkbox] {
    margin: 0 6px 0 0;
    cursor: pointer;
}

:global .claimer-diagram__container_controls-group {

    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

}

:global .claimer-diagram__container_diagram g.tick text {
    font-size: 11px;
    fill: #999;
}

:global #claimer_quantity_diagram g.quarters.axis > g:nth-child(2) > text {
    opacity: 0;
}

:global .claimer-diagram__container_diagram g.tick line {
    stroke: #f5f5f5;
}

:global .claimer-diagram__container_diagram .quarters g.tick line {
    stroke: #dadada;
    stroke-width: 1px;
}

:global .claimer-diagram__container_diagram .months g.tick line {
    stroke: #f6f6f6;
    stroke-width: 1px;
}

:global .claimer-diagram__container_diagram .years g.tick line,
:global .claimer-diagram__container_diagram .quarters line,
:global .claimer-diagram__container_diagram .gray-line {
    stroke: #dadada;
    stroke-width: 2px;
}

:global .claimer-diagram__container_diagram .years .domain {
    stroke: #dadada;
    stroke-width: 2px;
}

:global .claimer-diagram__container_diagram .years .domain {
    stroke: #dadada;
    stroke-width: 2px;
}

:global .claimer-diagram__container_diagram circle {
    stroke: transparent;
    cursor: pointer;
}
