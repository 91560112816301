:local .form-input {
    width: 88%;
    height: 34px;
    font-size: 16px;
    border: none;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
}

:local .search-form {
    display: flex;
    border: none;
}

:local .search-form i {
    color: #d1d1d1;
    font-size: 24px;
    padding: 6px 8px;
    /*width: 24px;*/
    /*height: 24px;*/
    text-align: center;
}

:local .search-input-clear {
    cursor: pointer;
}

:local .search-input-clear:hover {
    color: #000
}

:local .search-form button {
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
}
