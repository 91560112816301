/*!
* Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
*/
.Flag {
	display: inline-block;
	width: 16px;
	height: 11px;
	background: url("./flags.png") no-repeat;
}

.Flag-ad {
	background-position: -16px 0;
}

.Flag-ae {
	background-position: -32px 0;
}

.Flag-af {
	background-position: -48px 0;
}

.Flag-ag {
	background-position: -64px 0;
}

.Flag-ai {
	background-position: -80px 0;
}

.Flag-al {
	background-position: -96px 0;
}

.Flag-am {
	background-position: -112px 0;
}

.Flag-an {
	background-position: -128px 0;
}

.Flag-ao {
	background-position: -144px 0;
}

.Flag-ar {
	background-position: -160px 0;
}

.Flag-as {
	background-position: -176px 0;
}

.Flag-at {
	background-position: -192px 0;
}

.Flag-au {
	background-position: -208px 0;
}

.Flag-aw {
	background-position: -224px 0;
}

.Flag-ax {
	background-position: -240px 0;
}

.Flag-az {
	background-position: 0 -11px;
}

.Flag-ba {
	background-position: -16px -11px;
}

.Flag-bb {
	background-position: -32px -11px;
}

.Flag-bd {
	background-position: -48px -11px;
}

.Flag-be {
	background-position: -64px -11px;
}

.Flag-bf {
	background-position: -80px -11px;
}

.Flag-bg {
	background-position: -96px -11px;
}

.Flag-bh {
	background-position: -112px -11px;
}

.Flag-bi {
	background-position: -128px -11px;
}

.Flag-bj {
	background-position: -144px -11px;
}

.Flag-bm {
	background-position: -160px -11px;
}

.Flag-bn {
	background-position: -176px -11px;
}

.Flag-bo {
	background-position: -192px -11px;
}

.Flag-br {
	background-position: -208px -11px;
}

.Flag-bs {
	background-position: -224px -11px;
}

.Flag-bt {
	background-position: -240px -11px;
}

.Flag-bv {
	background-position: 0 -22px;
}

.Flag-bw {
	background-position: -16px -22px;
}

.Flag-by {
	background-position: -32px -22px;
}

.Flag-bz {
	background-position: -48px -22px;
}

.Flag-ca {
	background-position: -64px -22px;
}

.Flag-catalonia {
	background-position: -80px -22px;
}

.Flag-cd {
	background-position: -96px -22px;
}

.Flag-cf {
	background-position: -112px -22px;
}

.Flag-cg {
	background-position: -128px -22px;
}

.Flag-ch {
	background-position: -144px -22px;
}

.Flag-ci {
	background-position: -160px -22px;
}

.Flag-ck {
	background-position: -176px -22px;
}

.Flag-cl {
	background-position: -192px -22px;
}

.Flag-cm {
	background-position: -208px -22px;
}

.Flag-cn {
	background-position: -224px -22px;
}

.Flag-co {
	background-position: -240px -22px;
}

.Flag-cr {
	background-position: 0 -33px;
}

.Flag-cu {
	background-position: -16px -33px;
}

.Flag-cv {
	background-position: -32px -33px;
}

.Flag-cw {
	background-position: -48px -33px;
}

.Flag-cy {
	background-position: -64px -33px;
}

.Flag-cz {
	background-position: -80px -33px;
}

.Flag-de {
	background-position: -96px -33px;
}

.Flag-dj {
	background-position: -112px -33px;
}

.Flag-dk {
	background-position: -128px -33px;
}

.Flag-dm {
	background-position: -144px -33px;
}

.Flag-do {
	background-position: -160px -33px;
}

.Flag-dz {
	background-position: -176px -33px;
}

.Flag-ec {
	background-position: -192px -33px;
}

.Flag-ee {
	background-position: -208px -33px;
}

.Flag-eg {
	background-position: -224px -33px;
}

.Flag-eh {
	background-position: -240px -33px;
}

.Flag-england {
	background-position: 0 -44px;
}

.Flag-er {
	background-position: -16px -44px;
}

.Flag-es {
	background-position: -32px -44px;
}

.Flag-et {
	background-position: -48px -44px;
}

.Flag-eu {
	background-position: -64px -44px;
}

.Flag-fi {
	background-position: -80px -44px;
}

.Flag-fj {
	background-position: -96px -44px;
}

.Flag-fk {
	background-position: -112px -44px;
}

.Flag-fm {
	background-position: -128px -44px;
}

.Flag-fo {
	background-position: -144px -44px;
}

.Flag-fr {
	background-position: -160px -44px;
}

.Flag-ga {
	background-position: -176px -44px;
}

.Flag-gb {
	background-position: -192px -44px;
}

.Flag-gd {
	background-position: -208px -44px;
}

.Flag-ge {
	background-position: -224px -44px;
}

.Flag-gf {
	background-position: -240px -44px;
}

.Flag-gg {
	background-position: 0 -55px;
}

.Flag-gh {
	background-position: -16px -55px;
}

.Flag-gi {
	background-position: -32px -55px;
}

.Flag-gl {
	background-position: -48px -55px;
}

.Flag-gm {
	background-position: -64px -55px;
}

.Flag-gn {
	background-position: -80px -55px;
}

.Flag-gp {
	background-position: -96px -55px;
}

.Flag-gq {
	background-position: -112px -55px;
}

.Flag-gr {
	background-position: -128px -55px;
}

.Flag-gs {
	background-position: -144px -55px;
}

.Flag-gt {
	background-position: -160px -55px;
}

.Flag-gu {
	background-position: -176px -55px;
}

.Flag-gw {
	background-position: -192px -55px;
}

.Flag-gy {
	background-position: -208px -55px;
}

.Flag-hk {
	background-position: -224px -55px;
}

.Flag-hm {
	background-position: -240px -55px;
}

.Flag-hn {
	background-position: 0 -66px;
}

.Flag-hr {
	background-position: -16px -66px;
}

.Flag-ht {
	background-position: -32px -66px;
}

.Flag-hu {
	background-position: -48px -66px;
}

.Flag-ic {
	background-position: -64px -66px;
}

.Flag-id {
	background-position: -80px -66px;
}

.Flag-ie {
	background-position: -96px -66px;
}

.Flag-il {
	background-position: -112px -66px;
}

.Flag-im {
	background-position: -128px -66px;
}

.Flag-in {
	background-position: -144px -66px;
}

.Flag-io {
	background-position: -160px -66px;
}

.Flag-iq {
	background-position: -176px -66px;
}

.Flag-ir {
	background-position: -192px -66px;
}

.Flag-is {
	background-position: -208px -66px;
}

.Flag-it {
	background-position: -224px -66px;
}

.Flag-je {
	background-position: -240px -66px;
}

.Flag-jm {
	background-position: 0 -77px;
}

.Flag-jo {
	background-position: -16px -77px;
}

.Flag-jp {
	background-position: -32px -77px;
}

.Flag-ke {
	background-position: -48px -77px;
}

.Flag-kg {
	background-position: -64px -77px;
}

.Flag-kh {
	background-position: -80px -77px;
}

.Flag-ki {
	background-position: -96px -77px;
}

.Flag-km {
	background-position: -112px -77px;
}

.Flag-kn {
	background-position: -128px -77px;
}

.Flag-kp {
	background-position: -144px -77px;
}

.Flag-kr {
	background-position: -160px -77px;
}

.Flag-kurdistan {
	background-position: -176px -77px;
}

.Flag-kw {
	background-position: -192px -77px;
}

.Flag-ky {
	background-position: -208px -77px;
}

.Flag-kz {
	background-position: -224px -77px;
}

.Flag-la {
	background-position: -240px -77px;
}

.Flag-lb {
	background-position: 0 -88px;
}

.Flag-lc {
	background-position: -16px -88px;
}

.Flag-li {
	background-position: -32px -88px;
}

.Flag-lk {
	background-position: -48px -88px;
}

.Flag-lr {
	background-position: -64px -88px;
}

.Flag-ls {
	background-position: -80px -88px;
}

.Flag-lt {
	background-position: -96px -88px;
}

.Flag-lu {
	background-position: -112px -88px;
}

.Flag-lv {
	background-position: -128px -88px;
}

.Flag-ly {
	background-position: -144px -88px;
}

.Flag-ma {
	background-position: -160px -88px;
}

.Flag-mc {
	background-position: -176px -88px;
}

.Flag-md {
	background-position: -192px -88px;
}

.Flag-me {
	background-position: -208px -88px;
}

.Flag-mg {
	background-position: -224px -88px;
}

.Flag-mh {
	background-position: -240px -88px;
}

.Flag-mk {
	background-position: 0 -99px;
}

.Flag-ml {
	background-position: -16px -99px;
}

.Flag-mm {
	background-position: -32px -99px;
}

.Flag-mn {
	background-position: -48px -99px;
}

.Flag-mo {
	background-position: -64px -99px;
}

.Flag-mp {
	background-position: -80px -99px;
}

.Flag-mq {
	background-position: -96px -99px;
}

.Flag-mr {
	background-position: -112px -99px;
}

.Flag-ms {
	background-position: -128px -99px;
}

.Flag-mt {
	background-position: -144px -99px;
}

.Flag-mu {
	background-position: -160px -99px;
}

.Flag-mv {
	background-position: -176px -99px;
}

.Flag-mw {
	background-position: -192px -99px;
}

.Flag-mx {
	background-position: -208px -99px;
}

.Flag-my {
	background-position: -224px -99px;
}

.Flag-mz {
	background-position: -240px -99px;
}

.Flag-na {
	background-position: 0 -110px;
}

.Flag-nc {
	background-position: -16px -110px;
}

.Flag-ne {
	background-position: -32px -110px;
}

.Flag-nf {
	background-position: -48px -110px;
}

.Flag-ng {
	background-position: -64px -110px;
}

.Flag-ni {
	background-position: -80px -110px;
}

.Flag-nl {
	background-position: -96px -110px;
}

.Flag-no {
	background-position: -112px -110px;
}

.Flag-np {
	background-position: -128px -110px;
}

.Flag-nr {
	background-position: -144px -110px;
}

.Flag-nu {
	background-position: -160px -110px;
}

.Flag-nz {
	background-position: -176px -110px;
}

.Flag-om {
	background-position: -192px -110px;
}

.Flag-pa {
	background-position: -208px -110px;
}

.Flag-pe {
	background-position: -224px -110px;
}

.Flag-pf {
	background-position: -240px -110px;
}

.Flag-pg {
	background-position: 0 -121px;
}

.Flag-ph {
	background-position: -16px -121px;
}

.Flag-pk {
	background-position: -32px -121px;
}

.Flag-pl {
	background-position: -48px -121px;
}

.Flag-pm {
	background-position: -64px -121px;
}

.Flag-pn {
	background-position: -80px -121px;
}

.Flag-pr {
	background-position: -96px -121px;
}

.Flag-ps {
	background-position: -112px -121px;
}

.Flag-pt {
	background-position: -128px -121px;
}

.Flag-pw {
	background-position: -144px -121px;
}

.Flag-py {
	background-position: -160px -121px;
}

.Flag-qa {
	background-position: -176px -121px;
}

.Flag-re {
	background-position: -192px -121px;
}

.Flag-ro {
	background-position: -208px -121px;
}

.Flag-rs {
	background-position: -224px -121px;
}

.Flag-ru {
	background-position: -240px -121px;
}

.Flag-rw {
	background-position: 0 -132px;
}

.Flag-sa {
	background-position: -16px -132px;
}

.Flag-sb {
	background-position: -32px -132px;
}

.Flag-sc {
	background-position: -48px -132px;
}

.Flag-scotland {
	background-position: -64px -132px;
}

.Flag-sd {
	background-position: -80px -132px;
}

.Flag-se {
	background-position: -96px -132px;
}

.Flag-sg {
	background-position: -112px -132px;
}

.Flag-sh {
	background-position: -128px -132px;
}

.Flag-si {
	background-position: -144px -132px;
}

.Flag-sj {
	background-position: -160px -132px;
}

.Flag-sk {
	background-position: -176px -132px;
}

.Flag-sl {
	background-position: -192px -132px;
}

.Flag-sm {
	background-position: -208px -132px;
}

.Flag-sn {
	background-position: -224px -132px;
}

.Flag-so {
	background-position: -240px -132px;
}

.Flag-somaliland {
	background-position: 0 -143px;
}

.Flag-sr {
	background-position: -16px -143px;
}

.Flag-ss {
	background-position: -32px -143px;
}

.Flag-st {
	background-position: -48px -143px;
}

.Flag-sv {
	background-position: -64px -143px;
}

.Flag-sx {
	background-position: -80px -143px;
}

.Flag-sy {
	background-position: -96px -143px;
}

.Flag-sz {
	background-position: -112px -143px;
}

.Flag-tc {
	background-position: -128px -143px;
}

.Flag-td {
	background-position: -144px -143px;
}

.Flag-tf {
	background-position: -160px -143px;
}

.Flag-tg {
	background-position: -176px -143px;
}

.Flag-th {
	background-position: -192px -143px;
}

.Flag-tibet {
	background-position: -208px -143px;
}

.Flag-tj {
	background-position: -224px -143px;
}

.Flag-tk {
	background-position: -240px -143px;
}

.Flag-tl {
	background-position: 0 -154px;
}

.Flag-tm {
	background-position: -16px -154px;
}

.Flag-tn {
	background-position: -32px -154px;
}

.Flag-to {
	background-position: -48px -154px;
}

.Flag-tr {
	background-position: -64px -154px;
}

.Flag-tt {
	background-position: -80px -154px;
}

.Flag-tv {
	background-position: -96px -154px;
}

.Flag-tw {
	background-position: -112px -154px;
}

.Flag-tz {
	background-position: -128px -154px;
}

.Flag-ua {
	background-position: -144px -154px;
}

.Flag-ug {
	background-position: -160px -154px;
}

.Flag-um {
	background-position: -176px -154px;
}

.Flag-us {
	background-position: -192px -154px;
}

.Flag-uy {
	background-position: -208px -154px;
}

.Flag-uz {
	background-position: -224px -154px;
}

.Flag-va {
	background-position: -240px -154px;
}

.Flag-vc {
	background-position: 0 -165px;
}

.Flag-ve {
	background-position: -16px -165px;
}

.Flag-vg {
	background-position: -32px -165px;
}

.Flag-vi {
	background-position: -48px -165px;
}

.Flag-vn {
	background-position: -64px -165px;
}

.Flag-vu {
	background-position: -80px -165px;
}

.Flag-wales {
	background-position: -96px -165px;
}

.Flag-wf {
	background-position: -112px -165px;
}

.Flag-ws {
	background-position: -128px -165px;
}

.Flag-xk {
	background-position: -144px -165px;
}

.Flag-ye {
	background-position: -160px -165px;
}

.Flag-yt {
	background-position: -176px -165px;
}

.Flag-za {
	background-position: -192px -165px;
}

.Flag-zanzibar {
	background-position: -208px -165px;
}

.Flag-zm {
	background-position: -224px -165px;
}

.Flag-zw {
	background-position: -240px -165px;
}
