:local .Token {
  display: flex;
  font-family: SegoeWPSemibold, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 1px;
  padding: 3px 8px;
  white-space: nowrap;
  background: white;
  border: 1px solid #e7eaec;
	user-select: none;
}

:local .Button {
  padding: 0 0 0 5px;
  color: inherit;
  border: none;
  cursor: pointer;
  background-color: transparent;
	outline: none;
}

:local .Button:hover {
	color: #ed5565;
}

:local .Token[data-primary='true'] {
  color: #000000;
  border: none;
	background-color: #d3e5f2;
}

:local .Token[data-primary='true']:hover {
	background-color: #84cbff;
}
