.loading {
	display: flex;
	justify-content: center;
	padding: 10px 0;
	margin: 25vh 0;
}

.loading.loadingSmall {
	margin: 25px 0;
}

.loading.loadingFixed {
	position: fixed;
	align-items: center;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.loading.loadingCenter {
	position: fixed;
	align-items: center;
	left: 50%;
	top: 50%;
	z-index: 100;
	margin: 0;
	transform: translate(-50%, -50%);
}

.loading.loadingFullscreen {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.9);
	z-index: 9999;
	margin: 0;
}

@keyframes spinner {
	100% {
		transform: rotate(360deg);
	}
}

.image {
	width: 35px;
	height: 35px;
	animation: spinner 2s linear infinite;
}

@media (min-width: 1024px) {
	.loading {
		margin: 10vh 0;
	}
}
